import axios from "axios";
import { useEffect, useState } from "react";
import "./reset.css";
import { BASE_URL } from "../Auth/auth";
import leter from "../../../assets/письмо с инструкцией.png";
import { Link } from "react-router-dom";

const Reset = () => {
  let [status, setStatus] = useState();
  const [reset, setResetDate] = useState(() => {
    return {
      email: "",
    };
  });
  const [resetErr, setResetErr] = useState(() => {
    return {
      email: "",
    };
  });
  const changeInputEntry = (event) => {
    event.persist();
    setStatus(null);
    setResetDate((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const validate = (values) => {
    let errors = {};
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/.test(values.email) === false) {
      errors.email = "Поле заполнено неверно";
    }
    return errors;
  };

  const setReset = () => {
    axios
      .get(BASE_URL + "reset", { params: reset })
      .then((res) => {
        if (res.status == 200) {
          setStatus(200);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) setStatus(400);
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };

  useEffect(() => {
    reset && setResetErr(validate(reset));
  }, [reset]);
  return (
    <div className="reset container">
      {status == 200 ? (
        <>
          <h3>Восстановление пароля</h3>
          <div>
            <img src={leter} alt="letter" />
          </div>
          <div className="status-text">
            Вам отправлено письмо с инструкцией по восстановлению пароля
          </div>
          <div className="retry-reset" onClick={setReset}>
            Отправить инструкцию повторно
          </div>
          <div className="block-reset-btn">
            <Link to={"/enter"} className="btn">
              Вход в аккаунт
            </Link>
            <Link to={"/"} className="btn">
              Продолжить как гость
            </Link>
          </div>
        </>
      ) : (
        <>
          <h3>Восстановление пароля</h3>
          <input
            name="email"
            type="email"
            placeholder="Email"
            onChange={changeInputEntry}
          />
          {resetErr.email && <div className="err">{resetErr.email}</div>}
          {status == 400 && (
            <div className="err">Пользователя с таким email не существует</div>
          )}
          <button
            className="btn"
            disabled={Object.keys(resetErr).length !== 0 ? true : false}
            onClick={setReset}
          >
            Получить новый пароль
          </button>
          <Link className="link-back" to={"/"}>
            Вернуться на главную
          </Link>
        </>
      )}
    </div>
  );
};

export default Reset;
