import { useEffect, useState } from "react";
import "./notice.css";

const Notice = () => {
  useEffect(() => {
    localStorage.removeItem("s");
    localStorage.removeItem("cat");
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, []);
  return (
      <div className="notice container">
          <h2>ПРАВОВОЕ УВЕДОМЛЕНИЕ</h2>
          <p>Premier Logistic OÜ</p>

          <h3>ЮРИДИЧЕСКИЙ АДРЕС:</h3>
          <p>Tõru tn 15 Pirita linnaosa</p>
          <p>12011 Tallinn Harju maakond Estonia</p>

          <h3>РЕКВИЗИТЫ:</h3>
          <p>Premier Logistic OÜ</p>
          <p>Регистрационный номер: 11386669</p>
          <p>Расчетный счет IBAN: EE021010220070832010</p>
          <p>Наименование банка: AS SEB BANK</p>
          <p>Адрес банка: Tornimae 2 Tallinn 15010 Estonia</p>
          <p>SWIFT-код (BIC): EEUHEE2X</p>

          <h3>УПОЛНОМОЧЕННЫЙ ПРЕДСТАВИТЕЛЬ: Руслан Пагас</h3>

          <h3>КОНТАКТЫ:</h3>
          <p>
              Телефон: <a href="tel:+491723462609">+491723462609</a>
          </p>
          <p>
              Электронная почта:{" "}
              <a href="mailto:support@bestbookshop.eu">support@bestbookshop.eu</a>
          </p>

          <h3>ПОЧТОВЫЙ АДРЕС:</h3>
          <p>Ostrowski Str. 3</p>
          <p>04357 Leipzig Germany</p>
          <p>Получатель: S. Anokhin </p>

          <h3>ОНЛАЙН РАЗРЕШЕНИЕ СПОРОВ:</h3>
          <p>
              Все разногласия и споры, связанные с сайтом, урегулируются путем
              переговоров.
          </p>
          <p>
              Отправьте нам обоснованную жалобу на электронную почту{" "}
              <a href="mailto:support@bestbookshop.eu">support@bestbookshop.eu</a>.
              Обязательно укажите свое имя и контактные данные, дату подачи жалобы и
              свое рекомендуемое решение. Мы рассмотрим вашу жалобу в течение 15 дней
              и дадим ответ.{" "}
          </p>
          <p>
              Если вы не согласитесь с нашим ответом, вы можете обратиться в Комиссию
              по потребительским спорам для внесудебного разрешения спора. Подробнее:
              https://ttja.ee/tarbijavaidluste-komisjon.{" "}
          </p>
          <p>
              Также Европейская комиссия предоставляет платформу для онлайн-разрешения
              споров в Интернете (ODR-платформа). Подробнее:
              http://ec.europa.eu/consumers/odr/.
          </p>
      </div>
  );
};

export default Notice;
