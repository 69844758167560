import logoHeader from "../../assets/logoheader.svg";
import "./header.css";
import card from "../../assets/card.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../pages/Enter/Auth/auth";
import log from "../../assets/log.png";
import { ClientJS } from "clientjs";
import { useDispatch, useSelector } from "react-redux";
import { getInfoCart } from "../../store/Actions/Action";

function Header() {
  const info = useSelector((state) => state.Test.cartInfo);

  const dispatch = useDispatch();
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();
  useEffect(() => {
    dispatch(getInfoCart());
  }, []);

  const setSignOut = () => {
    localStorage.removeItem("scrollY");
    localStorage.removeItem("pageN");
    localStorage.removeItem("scrollStoryY");
    localStorage.removeItem("pageNStory");
    localStorage.removeItem("scrollYBooks");
    localStorage.removeItem("limitbook");
    axios
      .post(
        BASE_URL + "signout",
        {
          useragent: window.navigator.userAgent,
          fingerprint: fingerprint.toString(),
        },
        { withCredentials: true }
      )
      .then((res) => {
        localStorage.removeItem("access");
        if (res.status == 200) {
          window.location.assign("/");
        }
      })
      .catch((err) => {
        window.location.assign("/error");
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };

  return (
    <div className="header">
      <div className="logo-img">
        <Link
          to={"/"}
          onClick={() => {
            localStorage.removeItem("cat");
            localStorage.removeItem("s");
            localStorage.removeItem("scrollY");
            localStorage.removeItem("pageN");
            localStorage.removeItem("scrollStoryY");
            localStorage.removeItem("pageNStory");
            localStorage.removeItem("scrollYBooks");
            localStorage.removeItem("limitbook");
            window.scrollTo(0, 10);
          }}
          className="header-logo"
        >
          <img src={logoHeader} alt="logo" />
        </Link>
      </div>
      <div className="header-setting-menu">
        {localStorage.getItem("access") ? (
          <>
            {info && (
              <div className="info-cart">
                Товаров: {info.data.userHeader.totalCartItemsQuantity} (
                {info.data.userHeader.totalCartPrice.toFixed(2)} €)
              </div>
            )}
            <div>
              <Link
                onClick={() => {
                  localStorage.removeItem("scrollY");
                  localStorage.removeItem("pageN");
                  localStorage.removeItem("scrollStoryY");
                  localStorage.removeItem("pageNStory");
                  localStorage.removeItem("scrollYBooks");
                  localStorage.removeItem("limitbook");
                }}
                to="/user/cart"
              >
                <img src={card} alt="card" className="pointer" />
              </Link>
            </div>
            <div className="btn-entry pointer">
              <Link
                onClick={() => {
                  localStorage.removeItem("scrollY");
                  localStorage.removeItem("pageN");
                  localStorage.removeItem("scrollStoryY");
                  localStorage.removeItem("pageNStory");
                  localStorage.removeItem("scrollYBooks");
                  localStorage.removeItem("limitbook");
                }}
                to="user"
              >
                {info && info.data.userHeader.username}
              </Link>
            </div>
            <div>
              <img
                className="btn-sign-out"
                onClick={setSignOut}
                src={log}
                alt="user"
              />
            </div>
          </>
        ) : (
          <>
            {info && (
              <div className="info-cart">
                Товаров: {info.data.header.totalCartItemsQuantity} (
                {info.data.header.totalCartPrice.toFixed(2)} €)
              </div>
            )}
            <div>
              <Link
                onClick={() => {
                  localStorage.removeItem("scrollY");
                  localStorage.removeItem("pageN");
                  localStorage.removeItem("scrollStoryY");
                  localStorage.removeItem("pageNStory");
                  localStorage.removeItem("scrollYBooks");
                  localStorage.removeItem("limitbook");
                }}
                to="user/cart"
              >
                <img src={card} alt="card" className="pointer" />
              </Link>
            </div>
            <div className="btn-entry pointer">
              <Link
                onClick={() => {
                  localStorage.removeItem("scrollY");
                  localStorage.removeItem("pageN");
                  localStorage.removeItem("scrollStoryY");
                  localStorage.removeItem("pageNStory");
                  localStorage.removeItem("scrollYBooks");
                  localStorage.removeItem("limitbook");
                }}
                to="enter"
              >
                {" "}
                Войти
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
