import { useEffect, useState } from "react";
import "./pagination.css";
import { Link } from "react-router-dom";
import { send_request } from "../../pages/Enter/Auth/setAuthToken";
import { BASE_URL } from "../../pages/Enter/Auth/auth";
import addcart from "../../assets/галочка добавления в корзину.png";
import { useDispatch } from "react-redux";
import { getInfoCart } from "../../store/Actions/Action";
import CustomSelect from "../../components/select/select";

const PaginationComponent = ({ data, addSort, grid, onSetPosition }) => {
  const dispatch = useDispatch();
  let [isSuccessAdd, setSuccessAdd] = useState(false);
  let [isFullQuantity, setFullQuantity] = useState(false);
  let [scrollPosition, setScrollPosition] = useState(
    window.localStorage.getItem("scrollY")
      ? window.localStorage.getItem("scrollY")
      : null
  );
  let [quantity, setQuantity] = useState(1);
  const windowInnerWidth = document.documentElement.clientWidth;

  useEffect(() => {}, [data, isSuccessAdd]);

  useEffect(() => {
    if (scrollPosition) {
      window.scrollTo(0, scrollPosition);
    } else {
      window.scrollTo(0, windowInnerWidth / 4 + 30);
    }
  }, []);

  const [options, setOptions] = useState([
    {
      label: "Цена по возрастанию",
      value: "price:asc",
    },
    {
      label: "Цена по убыванию",
      value: "price:desc",
    },
    {
      label: "Год издания по возрастанию",
      value: "releaseYear:asc",
    },
    {
      label: "Год издания по убыванию",
      value: "releaseYear:desc",
    },
    {
      label: "По дате выставления (сначала новые)",
      value: "createdAt:desc",
    },
    {
      label: "По дате выставления (сначала старые)",
      value: "createdAt:asc",
    },
  ]);

  const handleChangeSelect = (e) => {
    addSort(e.value);
    localStorage.setItem("sort", e.value);
    localStorage.removeItem("scrollY");
    localStorage.removeItem("pageN");
  };

  return (
    <div className="pagination">
      {isSuccessAdd && (
        <div className="seccess-add">
          <img src={addcart} alt="добавлено в корзину" />
          Товар добавлен в корзину
        </div>
      )}
      {isFullQuantity && (
        <div className="seccess-add">
          Доступные экземпляры уже добавлены в корзину
        </div>
      )}
      <div className="pag-optionals">
        {grid !== 5 && (
          <>
            <CustomSelect
              options={options}
              placeHolder="Сортировать по"
              onChange={(e) => handleChangeSelect(e)}
            />
            {/* <select
              className="sort sort-items"
              defaultValue={localStorage.getItem("sort")}
              onChange={(e) => {
                addSort(e.target.value);
                localStorage.setItem("sort", e.target.value);
                localStorage.removeItem("scrollY");
                localStorage.removeItem("pageN");
              }}
            >
              <option value="">Сортировать по</option>
              <option value="price:asc">Цена по возрастанию</option>
              <option value="price:desc">Цена по убыванию</option>
              <option value="releaseYear:asc">
                Год издания по возрастанию
              </option>
              <option value="releaseYear:desc">Год издания по убыванию</option>
              <option value="createdAt:desc">
                По дате выставления (сначала новые)
              </option>
              <option value="createdAt:asc">
                По дате выставления (сначала старые)
              </option>
            </select> */}
            <div
              className="sort"
              onClick={() => {
                localStorage.removeItem("sort");
                localStorage.removeItem("s");
                localStorage.removeItem("cat");
                localStorage.removeItem("scrollY");
                localStorage.removeItem("pageN");
                window.location.assign("/");
              }}
            >
              Очистить параметры
            </div>
          </>
        )}
      </div>
      <div className={"catalog5"}>
        {data &&
          data.bookCards &&
          data.bookCards.map((item, index) => (
            <div key={index} className="card">
              <Link
                to={"/books/" + item.id}
                onClick={() => {
                  window.localStorage.setItem("scrollY", window.scrollY);
                  setScrollPosition(window.scrollY);
                }}
                key={index}
              >
                <div className="img-card">
                  <img src={item.image} />
                </div>
                <div className="desc-cart">
                  <p className="title-card">{item.title}</p>
                  <p className="author-card">{item.author}</p>
                  <p className="about-card">
                    Состояние: {item.condition}
                    <br />
                    Вес: {item.weight} г.
                  </p>
                </div>
              </Link>
              <div className="option-card">
                <span className="price-card">{item.price.toFixed(2)} €</span>
                <div
                  className="btn-add-bascet"
                  onClick={() => {
                    if (localStorage.getItem("access")) {
                      send_request
                        .post(BASE_URL + "user/cart", {
                          bookID: item.id,
                          quantity: 1,
                          category: 2,
                        })
                        .then((res) => {
                          dispatch(getInfoCart());
                          setSuccessAdd(true);
                          setTimeout(() => setSuccessAdd(false), 2000);
                        })
                        .catch((err) => {
                          if (err.response.status === 400) {
                            setFullQuantity(true);
                            setTimeout(() => setFullQuantity(false), 3000);
                          }
                          if (err.response.status === 500) {
                            window.location.assign("/error");
                          }
                        });
                    } else {
                      let books = localStorage.getItem("cart")
                        ? JSON.parse(localStorage.getItem("cart"))
                        : [];
                      if (
                        !books ||
                        books.find((book) => book.id === item.id) === undefined
                      ) {
                        books.push({
                          id: item.id,
                          quantity: quantity,
                        });
                        localStorage.setItem("cart", JSON.stringify(books));
                        dispatch(getInfoCart());
                        setSuccessAdd(true);
                        setTimeout(() => setSuccessAdd(false), 1000);
                      } else if (
                        books.find((book) => book.id === item.id).quantity +
                          quantity <=
                        item.quantity
                      ) {
                        let indBook = books.indexOf(
                          books.find((book) => book.id === item.id)
                        );
                        books[indBook].quantity =
                          books[indBook].quantity + quantity;

                        localStorage.setItem("cart", JSON.stringify(books));
                        dispatch(getInfoCart());
                        setSuccessAdd(true);
                        setTimeout(() => setSuccessAdd(false), 1000);
                      } else {
                        setFullQuantity(true);
                        setTimeout(() => setFullQuantity(false), 3000);
                      }
                    }
                  }}
                >
                  в корзину
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PaginationComponent;
