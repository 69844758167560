import axios from "axios";
import { useEffect, useState } from "react";
import "./bookpage.css";
import { BASE_URL } from "../Enter/Auth/auth";
import Carousel from "nuka-carousel";
import { send_request } from "../Enter/Auth/setAuthToken";
import arrLeft from "../../assets/arr-left.svg";
import arrRight from "../../assets/arr-right.svg";
import addcart from "../../assets/галочка добавления в корзину.png";
import { useDispatch } from "react-redux";
import { getInfoCart } from "../../store/Actions/Action";

const BookPage = () => {
  let [data, setData] = useState();
  const dispatch = useDispatch();
  let [isSuccessAdd, setSuccessAdd] = useState(false);
  let [isFullQuantity, setFullQuantity] = useState(false);
  let [quantity, setQuantity] = useState(1);
  const regular = new RegExp("[0-9]{1,}");

  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, [data]);
  useEffect(() => {
    axios
      .get(BASE_URL + "books/" + window.location.pathname.match(regular)[0])
      .then((data) => {
        setData(data.data);
      })
      .catch((err) => {
        window.location.assign("/not-found");
      });
  }, [isSuccessAdd]);

  let description =
    data && data.book && data.book.description
      ? data.book.description.split("\\n")
      : null;

  return (
    <div className="book-page container">
      {isSuccessAdd && (
        <div className="seccess-add">
          <img src={addcart} alt="добавлено в корзину" />
          Товар добавлен в корзину
        </div>
      )}
      {isFullQuantity && (
        <div className="seccess-add">
          Доступные экземпляры уже добавлены в корзину
        </div>
      )}
      {data && data.book && (
        <>
          <h2 className="book-title">{data.book.title}</h2>
          <div className="book-option-all">
            <Carousel
              renderCenterLeftControls={({ previousSlide }) => (
                <div className="btn-arrow-left" onClick={previousSlide}>
                  <img src={arrLeft} alt="left" />
                </div>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <div className="btn-arrow" onClick={nextSlide}>
                  <img src={arrRight} alt="right" />
                </div>
              )}
              adaptiveHeightAnimation="true"
            >
              {data.book.images &&
                data.book.images.map((i, index) => (
                  <img
                    key={index}
                    className="book-img"
                    src={i}
                    alt="img-book"
                  />
                ))}
            </Carousel>
            <div className="book-option">
              <p>
                <b>Автор:</b> {data.book.author}
              </p>
              <p>
                <b>ID товара:</b> {data.book.id}
              </p>
              <p>
                <b>Состояние:</b> {data.book.condition}
              </p>
              <p>
                <b>Доступное количество:</b> {data.book.quantity}
              </p>
              <p>
                <b>Вес:</b> {data.book.weight} гр.
              </p>
              <p>
                <b>Год издания:</b> {data.book.releaseYear}
              </p>
              <div className="book-price">
                <h3>{data.book.price.toFixed(2)}€</h3>
                <div className="book-price-option">
                  <div className="book-quantity">
                    {quantity > 1 && (
                      <div
                        className="quantity-btn"
                        onClick={() => {
                          quantity > 1 && setQuantity(quantity - 1);
                        }}
                      >
                        -
                      </div>
                    )}
                    <div>{quantity}</div>
                    {quantity < data.book.quantity && (
                      <div
                        className="quantity-btn"
                        onClick={() => {
                          quantity < data.book.quantity &&
                            setQuantity(quantity + 1);
                        }}
                      >
                        +
                      </div>
                    )}
                  </div>
                  <div
                    className="btn-to-card"
                    onClick={() => {
                      if (localStorage.getItem("access")) {
                        send_request
                          .post(BASE_URL + "user/cart", {
                            bookID: data.book.id,
                            quantity: quantity,
                          })
                          .then((res) => {
                            dispatch(getInfoCart());
                            setSuccessAdd(true);
                            setTimeout(() => setSuccessAdd(false), 5000);
                          })
                          .catch((err) => {
                            if (err.response.status === 500) {
                              window.location.assign("/error");
                            }
                          });
                      } else {
                        let books = localStorage.getItem("cart")
                          ? JSON.parse(localStorage.getItem("cart"))
                          : [];
                        if (
                          !books ||
                          books.find((book) => book.id === data.book.id) ===
                            undefined
                        ) {
                          books.push({
                            id: data.book.id,
                            quantity: quantity,
                          });
                          localStorage.setItem("cart", JSON.stringify(books));
                          dispatch(getInfoCart());
                          setSuccessAdd(true);
                          setTimeout(() => setSuccessAdd(false), 1000);
                        } else if (
                          books.find((book) => book.id === data.book.id)
                            .quantity +
                            quantity <=
                          data.book.quantity
                        ) {
                          let indBook = books.indexOf(
                            books.find((book) => book.id === data.book.id)
                          );
                          books[indBook].quantity =
                            books[indBook].quantity + quantity;

                          localStorage.setItem("cart", JSON.stringify(books));
                          dispatch(getInfoCart());
                          setSuccessAdd(true);
                          setTimeout(() => setSuccessAdd(false), 1000);
                        } else {
                          setFullQuantity(true);
                          setTimeout(() => setFullQuantity(false), 3000);
                        }
                      }
                    }}
                  >
                    В корзину
                  </div>
                </div>
                <div className="book-description">
                  <h3>Описание</h3>
                  <div>
                    {description &&
                      description.map((str, i) => <p key={`p_${i}`}>{str}</p>)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3>Дополнительная информация</h3>
            <table className="book-table">
              {data.book.isbn && (
                <tr>
                  <td>
                    <b>ISBN</b>
                  </td>
                  <td className="isbn">{data.book.isbn}</td>
                </tr>
              )}
              {data.book.publisher && (
                <tr>
                  <td>
                    <b>Издательство</b>
                  </td>
                  <td>{data.book.publisher}</td>
                </tr>
              )}

              {data.book.format && (
                <tr>
                  <td>
                    <b>Формат</b>
                  </td>
                  <td>{data.book.format}</td>
                </tr>
              )}

              {data.book.series && (
                <tr>
                  <td>
                    <b>Серия</b>
                  </td>
                  <td>{data.book.series}</td>
                </tr>
              )}

              {data.book.binding && (
                <tr>
                  <td>
                    <b>Переплёт</b>
                  </td>
                  <td>{data.book.binding}</td>
                </tr>
              )}
              {data.book.pages && (
                <tr>
                  <td>
                    <b>Количество страниц</b>
                  </td>
                  <td>{data.book.pages} стр.</td>
                </tr>
              )}
              <tr>
                <td>
                  <b>Повреждения</b>
                </td>
                <td>{data.book.isDamaged ? "Есть" : "Нет"}</td>
              </tr>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default BookPage;
