import { useEffect, useState } from "react";
import "./conditions.css";

const Conditions = () => {
  useEffect(() => {
    localStorage.removeItem("s");
    localStorage.removeItem("cat");
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, []);
  return (
      <div className="conditions container">
          <h2>УСЛОВИЯ ВОЗВРАТА ТОВАРА</h2>

          <h3>ПРАВО НА ОТКАЗ ОТ ДОГОВОРА:</h3>
          <p>
              Вы имеете право отказаться от договора в любое время до момента
              получения уведомления об отправке заказа, отправив сообщение нам на
              электронную почту:{" "}
              <a href="mailto:support@bestbookshop.eu">support@bestbookshop.eu</a>.{" "}
          </p>
          <p>
              После получения уведомления об отправке заказа вы имеете право вернуть
              Товар без объяснения причин в течение 14 календарных дней после
              получения Товара.
          </p>
          <p>
              Для того чтобы воспользоваться правом отказа от договора, вам необходимо
              отправить нам заявление о вашем решении в свободной форме по электронной
              почте:{" "}
              <a href="mailto:support@bestbookshop.eu">support@bestbookshop.eu</a> с
              обязательным указанием имени, фамилии, почтового адреса, наименования
              Товара, даты заказа и даты получения Товара или воспользоваться Формой
              отказа, приведенной ниже.{" "}
          </p>

          <h3>ПРАВО НА ОТКАЗ ОТ ДОГОВОРА НЕ ДЕЙСТВУЕТ В СЛЕДУЮЩИХ СЛУЧАЯХ:</h3>
          <p>
              Право отказа не распространяется на договоры купли-продажи периодических
              изданий (газет, журналов).
          </p>
          <p>
              Право отказа не распространяется на потребителей, которые не принадлежат
              к государству-члену Европейского Союза на момент заключения договора и
              чье единственное место жительства и адрес доставки находятся за
              пределами Европейского Союза на момент заключения договора.
          </p>
          <p>
              Право отказа не применяется, если Покупатель является юридическим лицом.
          </p>

          <h3>ПОСЛЕДСТВИЯ ОТКАЗА ОТ ДОГОВОРА:</h3>
          <p>
              Если вы воспользуетесь своим правом на отступление, мы выплатим вам все
              денежные средства, которые получили от вас, включая расходы на доставку.{" "}
          </p>
          <p>
              Если вы частично возвращаете заказанные Товары, BESTBOOKSHOP возмещает
              вам стоимость доставки пропорционально количеству заказанных Товаров.
          </p>
          <p>
              Комиссия за банковский перевод или конвертацию не возвращается, т.к.
              является платой за услугу сторонней организации – оператора платежа.
          </p>
          <p>
              BESTBOOKSHOP может отказать вам в возврате денежных средств до тех пор,
              пока не получит обратно предмет договора, или пока вы не предоставите
              доказательства того, что вернули Товар, в зависимости от того, что
              произойдет раньше.
          </p>
          <p>
              BESTBOOKSHOP возвращает вам денежные средства в течение 14 календарных
              дней с момента получения заявления об отказе на тот же банковский счет,
              с которого был оплачен Товар, но не ранее чем через 3 дня после
              получения отправленного вами Товара.{" "}
          </p>
          <p>
              Возвращенный Товар осматривается на предмет наличия повреждений,
              возникших после передачи Товара Покупателю. В случае обнаружения таких
              следов, заявление (претензия) Покупателя не удовлетворяется, денежные
              средства – не возвращаются.
          </p>

          <h3>ВОЗВРАТ ТОВАРА:</h3>
          <p>
              Пожалуйста, отправьте нам Товар обратно в оригинальной упаковке со всеми
              принадлежностями и всеми упаковочными компонентами на почтовый адрес
              BESTBOOKSHOP: Ostrowski Str. 3, 04357, Leipzig, Germany. Получатель: S.
              Anokhin.
          </p>
          <p>
              {" "}
              При необходимости используйте защитную внешнюю упаковку. Если у вас
              больше нет оригинальной упаковки, используйте подходящую упаковку для
              обеспечения надлежащей защиты от повреждений при транспортировке.
          </p>

          <h3>ФОРМА ОТКАЗА:</h3>
          <p>
              Заполните данную форму и отправьте ее по электронной почте:{" "}
              <a href="mailto:support@bestbookshop.eu">support@bestbookshop.eu</a>{" "}
          </p>
          <p>
              Настоящим заявлением я отзываю договор, заключенный мной на покупку
              следующих товаров:
              <br/>
              <br/>
              ___________________________________________________________
              <br/>
              <br/>
              ___________________________________________________________
              <br/>
              <br/>
              Заказан (дата) ____________ / получен (дата) __________________
              <br/>
              <br/>
              ____________________________________________________________
              <br/>
              <br/>
              Имя потребителя
              <br/>
              <br/>
              ____________________________________________________________
              <br/>
              <br/>
              Адрес потребителя
              <br/>
              <br/>
              ____________________________________________________________
              <br/>
              <br/>
              Подпись потребителя (только если заявление на бумажном носителе)
              <br/>
              <br/>
              __________________________
              <br/>
              <br/>
              Дата
          </p>
      </div>
  );
};

export default Conditions;
