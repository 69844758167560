import './pagination-panel.css'

const PaginationPanel=({nextPage})=> {
    return (
        <div className="pagination-panel">
            <button onClick={nextPage} className='btn'>Посмотреть еще</button>
        </div>
    );
}

export default PaginationPanel;