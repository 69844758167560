import { Outlet } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import Hero from "./components/hero/Hero";
import Footer from "./components/footer/Footer";
import UpBtn from "./components/upBtn/UpBtn";
import { send_request } from "./pages/Enter/Auth/setAuthToken";
import store from "./store/store";
import { Provider } from "react-redux";

function App() {
  return (
    <Provider store={store}>
      <Header />
      <Hero />
      <Outlet />
      <UpBtn />
      <Footer />
    </Provider>
  );
}

export default App;
