import { useEffect, useState } from "react";
import "./story-order.css";
import { BASE_URL } from "../Enter/Auth/auth";
import { send_request } from "../Enter/Auth/setAuthToken";
import { Link } from "react-router-dom";
import PaginationPanel from "../../components/paginationPanel/paginationPanel";

const StoryOrder = () => {
  let [orderPayPal, setOrderPayPal] = useState([]);
  let [scrollPosition, setScrollPosition] = useState(
    window.localStorage.getItem("scrollY")
      ? window.localStorage.getItem("scrollY")
      : null
  );

  let [limit, setLimit] = useState(
    window.localStorage.getItem("pageNStory")
      ? window.localStorage.getItem("pageNStory") + 5
      : 20
  );
  let [isLimit, setIsLimit] = useState(true);

  let [story, setStory] = useState();
  useEffect(() => {
    if (scrollPosition) {
      window.scrollTo(0, scrollPosition);
    } else {
      document.getElementsByClassName("hero-optional")[0] &&
        document.getElementsByClassName("hero-optional")[0].scrollIntoView();
    }
  }, []);
  useEffect(() => {
    send_request
      .get(BASE_URL + "user/orders", { params: { limit: limit, id: 0 } })
      .then((res) => {
        if (res.data.orders == null) {
        } else if (res.data.orders.length < limit || limit <= 0) {
          setIsLimit(false);
        } else {
          setIsLimit(true);
        }
        setStory(res.data.orders);
      })
      .catch((err) => {
        window.location.assign("/");
      });
  }, [limit]);
  useEffect(() => {
    let arr = [];
    story &&
      story.stores &&
      story.stores.map((i) => {
        i.books.map((item) => {
          arr.push({
            name: item.title,
            unit_amount: {
              currency_code: "EUR",
              value: +item.price.toFixed(2),
            },
            quantity: item.quantity,
          });
        });
      });
    setOrderPayPal(arr);
  }, [story, limit]);

  const nextPage = () => {
    if (isLimit) {
      localStorage.setItem("scrollStoryY", window.scrollY);
      localStorage.setItem("pageNStory", Math.trunc(limit + 5));
      setLimit(limit + 5);
    }
  };
  return (
    <div className="story-order container">
      <h2>Мои заказы</h2>
      {story ? (
        <>
          {story.map((order, ind) => (
            <div className="cart" key={ind}>
              <div className="handler-order">
                <h3>Номер заказа: {order.id}</h3>
                <div className="status-order">{order.status}</div>
              </div>
              <p>{order.createdAt}</p>
              <div>
                {order.stores.map((i, index) => (
                  <div key={index}>
                    <div className="brand">{i.brand}</div>
                    <table>
                      {i.books.map((item, ind) => (
                        <tr key={ind}>
                          <td className="img-prod">
                            <img src={item.image} alt={item.title} />
                          </td>
                          <td className="description-prod">
                            <h3>{item.title}</h3>
                          </td>
                          <td className="quantity-prod">
                            <div className="book-quantity">{item.quantity}</div>
                          </td>
                          <td className="price-prod">
                            {item.price.toFixed(2)} €
                          </td>
                        </tr>
                      ))}
                    </table>
                    {i.shipmentDetails && (
                      <div>
                        <div>Отправлено: {i.shipmentDetails.sentAt}</div>
                        <div className="track-num">
                          Трекномер: {i.shipmentDetails.trackingNumber}
                        </div>
                      </div>
                    )}
                    {order.status == "Ожидает оплаты" && (
                      <div className="pay-order-btn">
                        <Link
                          className="btn"
                          onClick={() => {
                            localStorage.setItem(
                              "scrollStoryY",
                              window.scrollY
                            );
                            setScrollPosition(window.scrollY);
                          }}
                          to={"pay/" + order.id}
                        >
                          Оплатить
                        </Link>
                      </div>
                    )}

                    {order.status == "Оплачено" && (
                      <div className="pay-order-btn">
                        <button
                          onClick={() => {
                            send_request
                              .get(
                                BASE_URL + "user/download-check/" + order.id,
                                {
                                  headers: {
                                    "Content-Type": "application/pdf",
                                  },
                                  responseType: "blob",
                                }
                              )
                              .then((res) => {
                                const url = window.URL.createObjectURL(
                                  new Blob([res.data])
                                );
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute(
                                  "download",
                                  order.id + ".pdf"
                                );
                                document.body.appendChild(link);
                                link.click();
                              })
                              .catch((err) => {
                                if (err.response.status === 500) {
                                  window.location.assign("/error");
                                }
                              });
                          }}
                          className="btn"
                        >
                          Скачать чек
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="pag-comp">
            {isLimit && <PaginationPanel nextPage={nextPage} />}
          </div>
        </>
      ) : story && story.length < 1 ? (
        <div className="cart">
          <br />
          <b>Здесь пока ничего нет</b>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default StoryOrder;
