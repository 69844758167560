import { useEffect, useState } from "react";
import "./terms.css";

const Terms = () => {
  useEffect(() => {
    localStorage.removeItem("s");
    localStorage.removeItem("cat");
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, []);
  return (
      <div className="terms container">
          <h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>
          <h3>
              §1 ОБЩИЕ ПОЛОЖЕНИЯ И УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ИНТЕРНЕТ-МАГАЗИНА
              BESTBOOKSHOP{" "}
          </h3>
          <p>
              1.1 Владельцем интернет-магазина «BESTBOOKSHOP» (далее – BESTBOOKSHOP)
              является Premier Logistic OÜ, регистрационный номер 11386669,
              юридический адрес: Tõru tn 15, Pirita linnaosa, Tallinn Harju maakond,
              12011, Estonia.
          </p>
          <p>
              1.2 Общие положения и условия использования BESTBOOKSHOP относятся ко
              всем правовым отношениям фирмы Premier Logistic OÜ (далее –
              «Администратор BESTBOOKSHOP») с пользователями интернет-страницей
              https://bestbookshop.eu и других, связанных с ней, страниц (именуемых далее
              «Интернет-страницами»).{" "}
          </p>
          <p>
              1.3 Администратор BESTBOOKSHOP управляет интернет-магазином
              «BESTBOOKSHOP», с помощью которого пользователи имеют возможность
              покупать Товар, а именно, книги, журналы, газеты, аудио- и
              видеопродукцию и т.п.{" "}
          </p>
          <p>
              1.4 Пользователем является каждый, кто использует интернет-страницу в
              качестве Покупателя. Покупатели, совершающие сделки через BESTBOOKSHOP,
              действуют от собственного имени и за свой счет.
          </p>
          <p>
              1.5 Посещение интернет-магазина и поиск в предложенном ассортименте
              Товаров не требует регистрации.
          </p>
          <p>
              1.6 Пользователь имеет возможность ознакомиться с Пользовательским
              соглашением на интернет-странице, сохранить его и распечатать. При
              посещении и использовании сайта просим вас также ознакомиться с
              Уведомлением о конфиденциальности и защите персональных данных и
              Условиями возврата товара.{" "}
          </p>
          <p>
              1.7 Помимо настоящего Пользовательского соглашения, отношения между
              Покупателем и BESTBOOKSHOP подчинены законодательству Эстонской
              Республики, Европейского союза, регулируются порядком обработки
              персональных данных, прейскурантом на книги (другие товары) и их
              доставку Покупателю. Законодательное подчинение действует только в
              случае проживания Покупателя на территории Эстонской Республики.
              Действие Венской конвенции о договорах купли-продажи товаров исключено.
          </p>
          <p>1.8 С Публичной офертой можно ознакомиться, перейдя по ссылке.</p>

          <h3>§2 ЗАКЛЮЧЕНИЕ ДОГОВОРА</h3>
          <p>
              2.1 Если Покупатель приобретает Товары, предлагаемые на сайте
              https://bestbookshop.eu, то такое взаимное соглашение является договором,
              заключенным с использованием средств связи на основании ст. 52
              Обязательственно-правового закона и в соответствии с директивой 97/7/ЕС
              Европейского парламента и Совета ЕС о защите прав потребителей в случае
              договоров, заключенных с использованием средств связи, и Законом о
              защите прав потребителей.
          </p>
          <p>
              2.2 Основанием для заключения договора купли-продажи является
              произведенный и подтвержденный Пользователем заказ товара.{" "}
          </p>
          <p>
              2.3 Договор считается вступившим в силу, когда одна сторона принимает, а
              другая сторона подтверждает его условия. В этом случае, Покупатель
              обязуется оплатить покупную цену, а Продавец обязуется передать или
              доставить по почте купленный Товар согласно договоренностям.
          </p>

          <h3>§3 ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
          <p>
              3.1 BESTBOOKSHOP обрабатывает персональные данные в соответствии с общим
              Постановлением о защите персональных данных и другими законами о защите
              данных.
          </p>
          <p>
              3.2 Пользователь дает свое согласие на сбор, обработку, использование и
              хранение личных данных для выполнения договора. Личные данные,
              необходимые для заключения и выполнения договора между Пользователем и
              Администратором BESTBOOKSHOP, включают: имя, фамилию, контактные данные
              (номер телефона, почтовый адрес, адрес электронной почты). Для
              анонимного анализа данных сохраняется название файла, дата и время
              пользования файлом, объем переданной информации, статус доступа (файл
              передан, файл не найден и т.д.) и описание типа использованного сетевого
              браузера.{" "}
          </p>
          <p>
              3.3 Администратор BESTBOOKSHOP осознает свою ответственность за защиту
              конфиденциальности предоставленных личных данных Пользователей.{" "}
          </p>
          <p>
              3.4 Пользователь имеет право в любое время отозвать свое разрешение на
              хранение личной информации. В таком случае, Администратор BESTBOOKSHOP
              обязуется аннулировать личные данные Пользователя.{" "}
          </p>
          <p>
              3.5 Пользователь имеет право письменно запросить у Администратора
              BESTBOOKSHOP информацию о том, какие личные данные сохранены на сайте.
          </p>
          <p>
              3.6 Дополнительную информацию о том, как BESTBOOKSHOP обрабатывает ваши
              персональные данные, можно в Уведомлении о конфиденциальности и защите
              персональных данных.
          </p>

          <h3>§4 ОФОРМЛЕНИЕ ЗАКАЗА</h3>
          <p>
              4.1 Для заказа товаров Покупатель может зарегистрироваться в качестве
              Пользователя интернет-магазина «BESTBOOKSHOP» или оформить заказ без
              предварительной регистрации.{" "}
          </p>
          <p>4.2 Цены на сайте указаны в евро (EUR).</p>
          <p>
              4.3 Налоги, применимые к Товарам, включены в их цену, за исключением
              отображения цен для клиентов за пределами Европейского Союза.
          </p>
          <p>
              4.4 Покупатель выбирает желаемый Товар(ы), определяет количество и
              помещает его в Корзину.{" "}
          </p>
          <p>
              4.5 В Корзине Покупатель может просмотреть добавленный Товар(ы),
              изменить их количество и ознакомиться со стоимостью доставки, выбрав
              страну доставки. Нажав на кнопку «Далее» Покупатель переходит на
              страницу оформления заказа, где вводит данные, необходимые для
              оформления заказа и выбирает способ оплаты.{" "}
          </p>
          <p>
              4.6 Плата за доставку Товара добавляется к цене заказанного товара в
              соответствии со способом доставки, выбранным Покупателем.
          </p>
          <p>
              4.7 Заказ считается оформленным после перенаправления Покупателя на
              сторонний сайт для оплаты Заказа.{" "}
          </p>
          <p>
              4.8 Заказ можно оплатить любым способом, предложенным на сайте.
              Подтверждение об оплате заказа придет на электронную почту, указанную
              Покупателем при оформлении заказа.
          </p>
          <p>
              4.9 После оформления заказа Товар резервируется, неоплаченный в течение
              10 дней заказ – аннулируется.
          </p>
          <p>
              4.10 BESTBOOKSHOP не хранит данные вашей платежной карты или другую
              платежную информацию.
          </p>
          <p>
              4.11 Техническая безопасность платежей обеспечивается сервисами,
              принимающими оплату.
          </p>
          <p>
              4.12 Заказ считается выполненным после отправки BESTBOOKSHOP Товара
              Покупателю на указанный адрес.
          </p>

          <h3>§5 ПОРЯДОК РЕГИСТРАЦИИ</h3>
          <p>
              5.1 Покупатель может зарегистрироваться в качестве постоянного
              Пользователя на сайте https://bestbookshop.eu. Регистрация на сайте
              бесплатная.
          </p>
          <p>
              5.2 При регистрации Пользователь вносит свое сетевое имя, выбирает
              пароль, заполняет все поля регистрационной формы и подтверждает согласие
              с Пользовательским соглашением. Связь между Администратором BESTBOOKSHOP
              и Пользователем происходит через указанный при регистрации адрес
              электронной почты. Пользователь должен держать свой пароль в секрете. В
              случае изменения данных, Пользователь обязан внести возникшие изменения.
          </p>
          <p>
              5.3 Регистрация разрешена только полностью дееспособным лицам.
              Несовершеннолетним лицам регистрация запрещена. Каждый Пользователь
              имеет право только на один регистрационный доступ. Передача своего
              доступа вторым лицам запрещена.
          </p>
          <p>
              5.4 По завершении процедуры регистрации, между Пользователем и
              Администратором BESTBOOKSHOP вступает в силу договор на пользование
              интернет-страницей (далее – «Договор пользования»). До завершения
              процедуры регистрации Пользователь может исправить свои данные с помощью
              клавиатуры или компьютерной мыши в соответствующих регистрационных
              полях.
          </p>
          <p>
              5.5 Регистрация Пользователя не ограничена сроками. Пользователь может в
              любое время прекратить действие своего Договора пользования, сообщив об
              этом письменно или по электронной почте support@bestbookshop.eu.
          </p>
          <p>
              5.6 Право чрезвычайного прекращения договора остается действительным для
              обеих сторон. Данные Пользователя удаляются в течение недели после
              поступления заявления о прекращении договора.
          </p>
          <p>
              5.7 Каждый Пользователь должен незамедлительно информировать
              Администратора BESTBOOKSHOP при подозрении на взлом своего входа в
              интернет-магазин.{" "}
          </p>
          <p>
              5.8 Пользователь исключительно сам несет ответственность за все
              действия, которые предпринимаются с использованием его регистрационного
              доступа, и освобождает Администратора BESTBOOKSHOP от любых требований
              третьих лиц по возмещению ущерба, за исключением случаев, когда
              Потребитель не ответственен за злоупотребление.
          </p>
          <p>
              5.9 После регистрации Покупатель может войти на сайт интернет-магазина,
              используя свою электронную почту и пароль, и оформить заказ на Товары.
          </p>

          <h3>§6 ДОСТАВКА ТОВАРОВ</h3>
          <p>
              6.1 Стоимость доставки Товара зависит от страны назначения и веса
              заказа. Узнать точную стоимость доставки можно перед оформлением заказа
              в Корзине с Товаром.{" "}
          </p>
          <p>
              6.2 Адрес доставки Покупатель указывает при оформлении Заказа на
              приобретение Товара.
          </p>
          <p>
              6.3 Срок доставки Товара Покупателю состоит из срока обработки заказа и
              условий службы доставки DHL. Доставка Товара занимает от 3 до 30 рабочих
              дней, в зависимости от страны, загруженности службы доставки и
              таможенной службы. Этот срок не включает в себя срок комплектования.
          </p>
          <p>
              6.4 BESTBOOKSHOP не несет ответственность за увеличение сроков доставки
              почтовыми службами, т.к. они являются сторонними организациями,
              оказывающими услугу доставки. Все претензии, в случае нарушения сроков
              доставки, нарушении целостности или потере посылки следует направлять в
              службу, услуга которой была заказана.
          </p>

          <h3>§7 ПРАВО НА ОТСТУПЛЕНИЕ ОТ ДОГОВОРА</h3>
          <p>
              7.1 Покупатель имеет право отказаться от договора купли-продажи в любое
              время до момента получения уведомления об отправке заказа, отправив
              соответствующее уведомление на электронную почту support@bestbookshop.eu.
          </p>
          <p>
              7.2 После получения уведомления об отправке заказа Покупатель имеет
              право вернуть Товар без объяснения причин в течение 14 календарных дней
              после получения Товара.
          </p>
          <p>
              7.3 Право отказа не распространяется на договоры купли-продажи
              периодических изданий (газет, журналов).
          </p>
          <p>
              7.4 Право отказа не распространяется на потребителей, которые не
              принадлежат к государству-члену Европейского Союза на момент заключения
              договора и чье единственное место жительства и адрес доставки находятся
              за пределами Европейского Союза на момент заключения договора.
          </p>
          <p>
              7.5 Право отказа не применяется, если покупатель является юридическим
              лицом.
          </p>
          <p>
              7.6 Для отказа от договора Покупатель должен отправить интернет-магазину
              «BESTBOOKSHOP» заявление по электронной почте support@bestbookshop.eu
              с указанием имени, фамилии, почтового адреса Покупателя, наименования
              Товара, даты заказа и даты получения Товара. Если заявление на бумажном
              носителе – необходима подпись Покупателя.{" "}
          </p>
          <p>
              7.7 Если вы воспользуетесь своим правом на отступление, мы выплатим вам
              все денежные средства, которые получили от вас, включая расходы на
              доставку.{" "}
          </p>
          <p>
              7.8 Если Покупатель частично возвращает заказанные Товары, BESTBOOKSHOP
              возмещает стоимость доставки пропорционально количеству заказанных
              Товаров.
          </p>
          <p>
              7.9 Комиссия за банковский перевод или конвертацию не возвращается, т.к.
              является платой за услугу сторонней организации – оператора платежа.
          </p>
          <p>
              7.10 BESTBOOKSHOP может отказать в возврате средств до тех пор, пока не
              получит обратно предмет договора или пока Покупатель не предоставит
              доказательства того, что он вернул Товар, в зависимости от того, что
              произойдет раньше.
          </p>
          <p>
              7.11 BESTBOOKSHOP возвращает Покупателю денежные средства в течение 14
              календарных дней с момента получения заявления об отказе на тот же
              банковский счет, с которого был оплачен Товар, но не ранее чем через 3
              дня после получения нами Товара.
          </p>
          <p>
              7.12 Возвращенный Товар осматривается на предмет наличия повреждений,
              возникших после передачи Товара Покупателю. В случае обнаружения таких
              следов, заявление (претензия) Покупателя не удовлетворяется, денежные
              средства – не возвращаются.
          </p>
          <p>
              7.13 Покупатель возвращает Товар на почтовый адрес BESTBOOKSHOP:
              Ostrowski Str. 3, 04357, Leipzig, Germany. Получатель: S. Anokhin
          </p>
          <p>
              7.14 Дополнительную информацию об отступлении от договора, возврате
              Товара в BESTBOOKSHOP и возврате уплаченной за покупку суммы можно найти
              в разделе Условия возврата товара.
          </p>

          <h3>§8 ПРАВА ПОКУПАТЕЛЯ</h3>
          <p>
              8.1 Покупатель имеет право заказывать Товары по цене и на условиях,
              приведенных в интернет-магазине «BESTBOOKSHOP».
          </p>
          <p>
              8.2 Покупатель имеет право представить претензии в отношении Товара по
              электронной почте support@bestbookshop.eu. В случае наличия у Товара
              неуказанных на сайте существенных недостатков, Товар заменяется
              аналогичным, за исключением случаев, когда соответствующий Товар больше
              не продается. Если соответствующий Товар больше не продается, по выбору
              Покупателя Товар заменяется другим или договор купли-продажи
              расторгается, а Покупателю возвращается стоимость Товара.
          </p>
          <p>
              8.3 Покупатель имеет право отказаться от заказа Товара в соответствии с
              §7.
          </p>
          <p>
              8.4 В случае несоответствия Товара условиям договора, Покупатель имеет
              право использовать предусмотренные законом средства правовой защиты,
              предложенные в §12 настоящего Пользовательского соглашения.
          </p>

          <h3>§9 ОБЯЗАТЕЛЬСТВА ПОКУПАТЕЛЯ</h3>
          <p>
              9.1 При оформлении заказа Покупатель обязуется указать необходимые для
              обработки заказа данные: имя и фамилию, точный адрес (улица, номер дома,
              номер квартиры, почтовый индекс, город/населенный пункт или
              поселок/уезд), контактный телефон и адрес электронной почты.
          </p>
          <p>
              9.2 Если Покупатель и Получатель Товара являются разными лицами,
              Покупатель должен представить данные, указанные в пункте 9.1.
          </p>
          <p>
              9.3 Если представленные Покупателем данные являются неверными и/или
              неполными, и не соответствуют указанным в пункте 9.1 требованиям,
              BESTBOOKSHOP не гарантирует своевременную доставку.{" "}
          </p>
          <p>
              9.4 Оформляя заказ, Покупатель подтверждает, что он ознакомился и
              согласен с данным Пользовательским соглашением и Уведомлением о
              конфиденциальности и защите персональных данных.
          </p>

          <h3>§10 ПРАВА ИНТЕРНЕТ-МАГАЗИНА BESTBOOKSHOP</h3>
          <p>
              10.1 BESTBOOKSHOP имеет право вносить изменения в настоящее
              Пользовательское соглашение и Уведомление о конфиденциальности и защите
              персональных данных без предварительного уведомления, опубликовав новые
              действующие условия на сайте https://bestbookshop.eu.
          </p>
          <p>
              10.2 BESTBOOKSHOP имеет право в любое время без предварительного
              уведомления менять цены в интернет-магазине, опубликовав их на сайте
              https://bestbookshop.eu.
          </p>
          <p>
              10.3 BESTBOOKSHOP имеет право отказаться от выполнения заказа, если на
              сайте https://bestbookshop.eu. ошибочно указана неверная цена или товар
              отсутствует на складе, и в течение трех рабочих дней вернуть деньги за
              товар Покупателю, если товар был оплачен.
          </p>
          <p>
              10.4 BESTBOOKSHOP не гарантирует, что составленное издателем описание
              содержания книги, соответствует мнению Покупателя о содержании книги.
          </p>
          <p>
              10.5 Если Покупатель не исполняет обязательства, возложенные на него
              настоящими условиями заказа, BESTBOOKSHOP не гарантирует оформление
              заказа и/или своевременную доставку Товара.
          </p>
          <p>
              10.6 Если Покупатель не исполняет обязательство оплаты, BESTBOOKSHOP
              имеет право аннулировать заказ.
          </p>

          <h3>§11 ОБЯЗАТЕЛЬСТВА ИНТЕРНЕТ-МАГАЗИНА BESTBOOKSHOP</h3>
          <p>
              11.1 BESTBOOKSHOP обязуется доставлять клиенту Товар(ы) в корректном и
              комплектном виде.
          </p>
          <p>
              11.2 BESTBOOKSHOP обязуется принимать и документировать все претензии
              Покупателя.
          </p>
          <p>
              11.3 BESTBOOKSHOP обязуется рассмотреть претензии Покупателя в течение 7
              рабочих дней.
          </p>
          <p>
              11.4 Администратор BESTBOOKSHOP несет полную ответственность за товарные
              и правовые недостатки, а также за преднамеренное причинение вреда.
          </p>

          <h3>§12 ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h3>
          <p>
              12.1 В случае споров, связанных с сайтом, ко всем правам и обязанностям,
              предусмотренным в настоящем Пользовательском соглашении, и ко всем
              действиям, которые им регулируются, применяются Законы и Постановления
              Эстонской Республики.
          </p>
          <p>
              12.2 Все споры между сторонами, касающиеся посещения сайта или
              возникающие в связи с исполнением, толкованием или истечением срока
              действия договора, заключенного удаленно, решаются путем переговоров.
          </p>
          <p>
              12.3 Если вы считаете, что BESTBOOKSHOP нарушил правила защиты прав
              потребителей, например, в связи с предоставлением информации об услуге,
              удаленно заключенным договором или правом на отступление от него,
              пожалуйста, отправьте обоснованную жалобу на электронный адрес:
              support@bestbookshop.eu. Мы рассмотрим вашу жалобу в течение 15 дней
              и дадим ответ.
          </p>
          <p>
              12.4 В случае несогласия с нашим ответом, вы можете обратиться в
              Комиссию по потребительским спорам для внесудебного разрешения спора.
              Дополнительная информация: https://ttja.ee/tarbijavaidluste-komisjon.
              Рассмотрение жалоб в Комиссии является для сторон бесплатным.
          </p>
          <p>
              12.5 Если между BESTBOOKSHOP и потребителем возникает спор по вопросам
              защиты данных, любая из сторон имеет право обратиться как в Суд, так и в
              Инспекцию по защите данных. Дополнительная информация:
              https://www.aki.ee/et.
          </p>
          <p>
              12.6 Также Европейская комиссия предоставляет платформу для
              онлайн-разрешения споров в Интернете (ODR-платформа), которую вы можете
              найти по адресу: http://ec.europa.eu/consumers/odr/. Платформа ODR
              предназначена, прежде всего, но не исключительно, для урегулирования
              трансграничных споров, и позволяет потребителю проще найти подходящий
              для внесудебного урегулирования разногласий орган в другой стране-члене
              ЕС.
          </p>

          <h3>§13 ГИПЕРССЫЛКИ НА ВЕБ-САЙТЫ ТРЕТЬИХ ЛИЦ</h3>
          <p>
              13.1 Мы не несем ответственности за содержание других веб-сайтов, на
              которые ведут гиперссылки на нашем сайте или которые содержат
              гиперссылки на наш сайт. Гиперссылки, отображаемые на сайте,
              предназначены только для вашего удобства. Вы используете гиперссылки на
              другие веб-сайты и домашние страницы или размещенные на них гиперссылки
              под собственную ответственность. Мы не несем никакой ответственности за
              проверку или оценку других веб-сайтов, с которых или на которые ведут
              гиперссылки на нашем сайте. Мы не гарантируем доступность других
              веб-сайтов, а также не принимаем на себя никакой ответственности за
              работу, контент, продукты или услуги других веб-сайтов, включая их
              политику конфиденциальности и условия использования. Вам следует
              внимательно ознакомиться с условиями использования и политикой
              конфиденциальности других веб-сайтов, которые вы посещаете.
          </p>

          <h3>§14 ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h3>
          <p>
              14.1 Вся информация и контент на этом веб-сайте, включая товарные знаки,
              логотипы, текст, графику, значки кнопок, изображения, аудиозаписи,
              наборы данных и программное обеспечение, являются собственностью
              BESTBOOKSHOP или наших партнеров и защищены законами и правилами в сфере
              интеллектуальной собственности. Использование наших товарных знаков в
              любой форме и для любой цели не допускается без нашего прямого
              письменного разрешения, за исключением случаев, когда это прямо
              разрешено законом.
          </p>

          <h3>§15 ТОЧНОСТЬ ИНФОРМАЦИИ НА ДАННОМ САЙТЕ</h3>
          <p>
              15.1 Данные веб-страницы могут содержать непреднамеренные неточности или
              опечатки. В случае их обнаружения они могут быть исправлены по
              усмотрению BESTBOOKSHOP. Информация на данных веб-страницах регулярно
              обновляется, но неточности могут оставаться или возникать, если
              изменения произошли в период между обновлениями.{" "}
          </p>

          <h3>§16 ЦЕЛОСТНОСТЬ СИСТЕМЫ И НЕПОЛАДКИ НА ИНТЕРНЕТ-СТРАНИЦЕ</h3>
          <p>
              16.1 Пользователям запрещено использовать механизмы, программное
              обеспечение и прочие скрипты, которые могут привести к нарушению
              функционирования интернет-страницы, а также позволяющие генерировать или
              автоматизировать вызов интернет-страницы.
          </p>
          <p>
              16.2 Пользователям запрещено прибегать к действиям, вызывающим
              чрезмерную нагрузку инфраструктуры.
          </p>
          <p>
              16.3 Пользователям запрещено блокировать, переписывать или
              модифицировать содержания, выработанные Администратором BESTBOOKSHOP, а
              также нарушать функционирование интернет-страницы любым другим способом.
          </p>

          <h3>§17 ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</h3>
          <p>
              17.1 BESTBOOKSHOP не несет ответственности за любую задержку, или
              неисполнение, или любое другое неисполнение, вызванное обстоятельствами
              и препятствиями, над которыми у нас отсутствует разумный контроль и
              которые не было возможности предвидеть или предотвратить, включая
              забастовки, правительственные распоряжения, военные действия или
              общегосударственную чрезвычайную ситуацию, угрозы или акты терроризма,
              экологические или климатические аномалии, неисполнение обязательств
              третьими лицами, перебои в интернет-соединении и сбои компьютерного
              оборудования и программного обеспечения.{" "}
          </p>
          <p>
              17.2 В случае таких обстоятельств непреодолимой силы мы свяжемся с вами
              и постараемся как можно скорее найти решение задержкам.
          </p>

          <h3>§18 ИЗМЕНЕНИЕ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</h3>
          <p>
              Администратор BESTBOOKSHOP сохраняет за собой право в любое время и без
              указания причин вносить изменения в Пользовательское соглашение,
              опубликовав новые действующие условия на сайте https://bestbookshop.eu.
          </p>
      </div>
  );
};

export default Terms;
