import { useEffect, useState, useRef } from 'react';
import '../../App.css';
import './hero.css';
import bannerMain from '../../assets/banner.svg';
import search from '../../assets/seach icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { textSearch } from '../../store/Actions/Action';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../pages/Enter/Auth/auth';

const Hero = () => {
  const dispatch = useDispatch();
  const refContainer = useRef(null);
  const [totalBooks, setTotalBooks] = useState('');

  let [searchText, setSearchText] = useState();
  const text = useSelector((state) => state.Test.textSearch);

  useEffect(() => {
    dispatch(textSearch());
    axios
      .get(BASE_URL + 'books/total')
      .then((res) => setTotalBooks(res.data.total))
      .catch((err) => console.log(err));
  }, []);
  const searchParams = new URLSearchParams(window.location.search);

  let searchingText = searchParams.get('search');

  useEffect(() => {}, [searchingText]);
  return (
    <div className="hero container">
      <Link
        className="link-banner"
        to="/"
        onClick={() => {
          localStorage.removeItem('s');
          localStorage.removeItem('cat');
          localStorage.removeItem('scrollY');
          localStorage.removeItem('pageN');
          window.location.assign('/');
        }}
      >
        <img className="img-banner" src={bannerMain} alt="bookzone" />
      </Link>
      <div className="hero-optional">
        <div className="search">
          <div className="inp-block">
            <img
              src={search}
              className="pointer"
              onClick={() => {
                localStorage.removeItem('scrollY');
                localStorage.removeItem('pageN');
                dispatch(textSearch());
                window.location.assign(
                  '/search?search=' +
                    document
                      .getElementsByClassName('inp-search')[0]
                      .value.trim()
                );
              }}
              alt="search"
            />
            <input
              ref={refContainer}
              type="text"
              defaultValue={searchingText}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  localStorage.removeItem('scrollY');
                  localStorage.removeItem('pageN');
                  dispatch(textSearch());
                  window.location.assign(
                    '/search?search=' + e.target.value.trim()
                  );
                }
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              className="inp-search"
              placeholder={
                'Поиск среди ' + (totalBooks ?? totalBooks) + ' книг'
              }
            />
            <div>{text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
