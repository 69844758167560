import "./upBtn.css";
import upbtn from "../../assets/стрелка  вверх.png";

const UpBtn = () => {
  return (
    <img
      className="up-btn"
      onClick={() => {
        window.scrollTo(0, 0);
        localStorage.removeItem("scrollY");
        localStorage.removeItem("pageN");
        localStorage.removeItem("scrollStoryY");
        localStorage.removeItem("pageNStory");
      }}
      src={upbtn}
      alt="up"
    />
  );
};

export default UpBtn;
