import axios from "axios";
import { useEffect, useRef, useState } from "react";
import "./user-order.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { BASE_URL } from "../../pages/Enter/Auth/auth";
import Order from "../order/order";
import { send_request } from "../../pages/Enter/Auth/setAuthToken";
import { Link } from "react-router-dom";
import empty from "../../assets/пустая корзина покупателя.png";

const UserOrder = () => {
  let [stores, setStores] = useState(null);
  let [data, setData] = useState();
  let [user, setUser] = useState();
  let [cart, setCart] = useState();
  let [selectCountry, setSelectCountry] = useState(
    localStorage.getItem("country")
  );
  let [countries, setCountries] = useState();
  let [orderID, setOrderId] = useState();
  let [isCanPay, setIsCanPay] = useState(false);
  let [isClickBtn, setIsClickBtn] = useState(false);

  let [orderPayPal, setOrderPayPal] = useState([]);
  let [errorData, setErrorData] = useState({
    address: "",
    email: "",
    firstname: "",
    lastname: "",
    phoneNumber: "",
    comment: "",
  });

  useEffect(() => {
    let arr = [];
    cart &&
      cart.cart &&
      cart.cart.stores.map((i) => {
        i.books.map((item) => {
          arr.push({
            name: item.title,
            unit_amount: {
              currency_code: "EUR",
              value: +item.price.toFixed(2),
            },
            quantity: item.quantity,
          });
        });
      });
    setOrderPayPal(arr);
  }, [cart, selectCountry]);

  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
    axios
      .get(BASE_URL + "countries")
      .then((res) => {
        setCountries(
          res.data.countries.find(
            (i) => i.id === +localStorage.getItem("country")
          )
        );
        setCountries(res.data.countries);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
    if (localStorage.getItem("access")) {
      send_request.get(BASE_URL + "user").then((res) => {
        setData(res.data.user);
        setUser(res.data.user);
      });
    } else {
      if (
        localStorage.getItem("user") &&
        localStorage.getItem("user") == undefined
      ) {
        setData(JSON.parse(localStorage.getItem("user")));
      } else {
        localStorage.removeItem("user");
      }
    }
  }, []);

  useEffect(() => {
    data && setErrorData(validate(data));
    setIsCanPay(false);
    localStorage.setItem("user", JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    getCart();
  }, [selectCountry]);

  let availableBooks = [];
  let newBooks = JSON.parse(localStorage.getItem("cart"));

  const getCart = () => {
    if (localStorage.getItem("access")) {
      send_request
        .get(
          BASE_URL +
            "user/cart/details?country=" +
            (localStorage.getItem("country")
              ? localStorage.getItem("country")
              : 0)
        )
        .then((res) => {
          setStores(res.data.cart.stores);
          setCart(res.data);
        })
        .catch((err) => {
          setCart(null);
          if (err.response.status === 400) {
            setStores([]);
          }
          if (err.response.status === 500) {
            window.location.assign("/error");
          }
        });
    } else {
      axios
        .post(
          BASE_URL + "cart/details?country=" + localStorage.getItem("country"),
          { books: JSON.parse(localStorage.getItem("cart")) }
        )
        .then((res) => {
          setStores(res.data.cart.stores);
          setCart(res.data);
          res.data.cart.stores.forEach((element) => {
            element.books.forEach((book) => {
              availableBooks.push(+book.id);
            });
          });
        })
        .catch((err) => {
          if (err.response.status === 500) {
            window.location.assign("/error");
          }
        });
    }
  };

  const initialOptions = {
    //"client-id": "AXC3ABQ_pRf5Mu_40enAJEY4v_eRzyq3MiX7LqLhIz-EAmvL0_1wiISi-1lPHf0Li5ADXHHGLOyZnN_s",
    "client-id": "AWpXf6_xVv_dsEtKpko9QV89UKtnVkWEW91nGhNmj60n-aL7hIjHl3ht3WsH49S5tF9QpY24NbGF6C7D",
    currency: "EUR",
  };

  const createUserOrder = (actions) => {
    let dataUser = JSON.parse(localStorage.getItem("user"));
    dataUser.countryID = +localStorage.getItem("country");
    if (localStorage.getItem("access")) {
      send_request
        .post(BASE_URL + "user/order", { cart: cart.cart, user: dataUser })
        .then((res) => {
          setOrderId(res.data.orderID);
          localStorage.setItem("orderId", res.data.orderID);
          setIsCanPay(true);
        })
        .catch((err) => {
          if (err.response.status === 500 || err.response.status === 400) {
            window.location.assign("/error");
          }
        });
    } else {
      axios
        .post(BASE_URL + "order", { cart: cart.cart, user: dataUser })
        .then((res) => {
          setOrderId(res.data.orderID);
          localStorage.setItem("orderId", res.data.orderID);
          setIsCanPay(true);
        })
        .catch((err) => {
          if (err.response.status === 500 || err.response.status === 400) {
            window.location.assign("/error");
          }
        });
    }
  };

  const validate = (values) => {
    let errors = {};
    if (!values.firstname) {
      errors.firstname = "Обязательное поле";
    } else if (/[0-9]/.test(values.firstname) === true) {
      errors.firstname = "Поле не должно содержать цифр";
    }

    if (!values.lastname) {
      errors.lastname = "Обязательное поле";
    } else if (values.lastname && /[0-9]/.test(values.lastname) === true) {
      errors.lastname = "Поле не должно содержать цифр";
    }

    if (!values.email) {
      errors.email = "Обязательное поле";
    } else if (
      /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/.test(values.email) === false
    ) {
      errors.email = "Поле заполнено неверно";
    }

    if (!values.address) {
      errors.address = "Обязательное поле";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "Обязательное поле";
    } else if (/^\+[1-9]\d{10,14}$/.test(values.phoneNumber) === false) {
      errors.phoneNumber = "Поле должно иметь вид (+123...)";
    }

    if (!values.address) {
      errors.address = "Обязательное поле";
    }
    return errors;
  };

  useEffect(() => {}, [isClickBtn]);

  const changeInputUser = (event) => {
    event.persist();

    setData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
    localStorage.setItem("user", JSON.stringify(data));
  };
  return (
    <div className="order container">
      {cart ? (
        <>
          {cart && <Order getCart={getCart} cartUser={cart.cart} />}
          <div className="user-order__stores">
          <h2>Оформление заказа</h2>
          <input
            name="firstname"
            placeholder="Имя"
            type="text"
            defaultValue={data && data.firstname}
            onChange={changeInputUser}
          />
          {errorData.firstname && (
            <div className="err">{errorData.firstname}</div>
          )}
          <input
            name="lastname"
            placeholder="Фамилия"
            type="text"
            defaultValue={data && data.lastname}
            onChange={changeInputUser}
          />
          {errorData.lastname && (
            <div className="err">{errorData.lastname}</div>
          )}
          <input
            name="email"
            placeholder="Email"
            type="email"
            defaultValue={data && data.email}
            onChange={changeInputUser}
          />
          {errorData.email && <div className="err">{errorData.email}</div>}

          <input
            name="phoneNumber"
            placeholder="Номер телефона"
            type="text"
            defaultValue={data && data.phoneNumber}
            onChange={changeInputUser}
          />
          {errorData.phoneNumber && (
            <div className="err">{errorData.phoneNumber}</div>
          )}
          {countries && countries.id && (
            <select
              placeholder="Страна"
              className="select-country"
              defaultValue={countries.id}
            >
              <option value={countries.id}>{countries.country}</option>
            </select>
          )}
          {countries && (
            <select
              onChange={(e) => {
                setSelectCountry(e.target.value);
                localStorage.setItem("country", e.target.value);
              }}
              className="select-country"
              defaultValue={
                localStorage.getItem("country")
                  ? localStorage.getItem("country")
                  : 0
              }
            >
              {countries.map((i, ind) => (
                <option key={ind} value={i.id}>
                  {i.country}
                </option>
              ))}
            </select>
          )}

          <input
            name="address"
            placeholder="Почтовый адрес"
            type="text"
            defaultValue={data && data.address}
            onChange={changeInputUser}
          />
          {errorData.address && <div className="err">{errorData.address}</div>}

          <textarea
            name="comment"
            placeholder="Комментарий к заказу"
            type="text"
            defaultValue={data && data.comment}
            onChange={changeInputUser}
          />
          {Object.keys(errorData).length == 0 && (
            <div>
              <h2>Способ оплаты</h2>

              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  onShippingChange={() => {
                    return true;
                  }}
                  fundingSource={"paypal"}
                  style={{ layout: "horizontal" }}
                  onClick={(data, actions) => {
                    createUserOrder();
                  }}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "EUR",
                            value: cart.cart.totalPrice,
                            breakdown: {
                              item_total: {
                                currency_code: "EUR",
                                value: cart.cart.totalBooksPrice,
                              },
                              shipping: {
                                value: cart.cart.totalShippingPrice,
                                currency_code: "EUR",
                              },
                            },
                          },
                          items: orderPayPal,
                        },
                      ],
                      payment_source: {
                        paypal: {
                          experience_context: {
                            return_url: BASE_URL + "user/cart",
                          },
                        },
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    const order = await actions.order.capture();
                    if (localStorage.getItem("access")) {
                      send_request
                        .post(
                          BASE_URL +
                            "order/" +
                            localStorage.getItem("orderId") +
                            "/payment",
                          { paymentDetails: data.orderID, paymentTypeID: 1 }
                        )
                        .then((res) => {
                          localStorage.removeItem("orderId");
                          window.location.assign("/successful-order");
                        })
                        .catch((err) => {
                          if (err.response.status === 500) {
                            window.location.assign("/error");
                          }
                        });
                    } else {
                      axios
                        .post(
                          BASE_URL +
                            "order/" +
                            localStorage.getItem("orderId") +
                            "/payment",
                          { paymentDetails: data.orderID, paymentTypeID: 1 }
                        )
                        .then((res) => {
                          localStorage.removeItem("orderId");
                          localStorage.removeItem("cart");
                          // localStorage.setItem("cart",
                          //   JSON.stringify(newBooks)
                          // );
                          window.location.assign("/successful-order");
                        })
                        .catch((err) => {
                          if (err.response.status === 500) {
                            window.location.assign("/error");
                          }
                        });
                    }
                  }}
                  onCancel={() => {
                    localStorage.removeItem("orderId");
                    localStorage.removeItem("cart");
                    window.location.assign("/successful-order");
                  }}
                  onError={(err) => {
                    window.location.assign("/successful-order");
                  }}
                />
                <PayPalButtons
                  onShippingChange={() => {
                    return true;
                  }}
                  fundingSource={"card"}
                  style={{ layout: "horizontal" }}
                  onClick={(data, actions) => {
                    createUserOrder();
                  }}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "EUR",
                            value: cart.cart.totalPrice,
                            breakdown: {
                              item_total: {
                                currency_code: "EUR",
                                value: cart.cart.totalBooksPrice,
                              },
                              shipping: {
                                value: cart.cart.totalShippingPrice,
                                currency_code: "EUR",
                              },
                            },
                          },
                          items: orderPayPal,
                        },
                      ],
                      payment_source: {
                        paypal: {
                          experience_context: {
                            return_url: BASE_URL + "user/cart",
                          },
                        },
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    const order = await actions.order.capture();
                    if (localStorage.getItem("access")) {
                      send_request
                        .post(
                          BASE_URL +
                            "order/" +
                            localStorage.getItem("orderId") +
                            "/payment",
                          { paymentDetails: data.orderID, paymentTypeID: 1 }
                        )
                        .then((res) => {
                          localStorage.removeItem("orderId");
                          window.location.assign("/successful-order");
                        })
                        .catch((err) => {
                          if (err.response.status === 500) {
                            window.location.assign("/error");
                          }
                        });
                    } else {
                      axios
                        .post(
                          BASE_URL +
                            "order/" +
                            localStorage.getItem("orderId") +
                            "/payment",
                          { paymentDetails: data.orderID, paymentTypeID: 1 }
                        )
                        .then((res) => {
                          localStorage.removeItem("orderId");
                          localStorage.setItem(
                            "cart",
                            JSON.stringify(newBooks)
                          );
                          window.location.assign("/successful-order");
                        })
                        .catch((err) => {
                          if (err.response.status === 500) {
                            window.location.assign("/error");
                          }
                        });
                    }
                  }}
                  onCancel={() => {
                    localStorage.removeItem("orderId");
                    localStorage.removeItem("cart");
                    window.location.assign("/successful-order");
                  }}
                  onError={(err) => {
                    window.location.assign("/successful-order");
                  }}
                />
              </PayPalScriptProvider>
            </div>
          )}
          </div>
        </>
      ) : (
        stores &&
        stores.length < 1 && (
          <div className="empty">
            <div className="empty-block">
              <h3>Здесь пока ничего нет</h3>

              <img src={empty} alt="Корзина пуста" />
              <Link className="btn" to={"/"}>
                Перейти к покупкам{" "}
              </Link>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default UserOrder;
