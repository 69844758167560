import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";

import App from "./App";
import Home from "./pages/Home/Home";
import Enter from "./pages/Enter/Enter";
import BookPage from "./pages/BookPage/BookPage";
import User from "./pages/User/User";
import Reset from "./pages/Enter/Reset/Reset";
import UserCard from "./components/userCard/UserCard";
import UserOrder from "./components/userOrder/UserOrder";
import Conditions from "./pages/static/Conditions/Conditions";
import Confidentiality from "./pages/static/Confidentiality/Confidentiality";
import Notice from "./pages/static/Notice/Notice";
import Terms from "./pages/static/Terms/Terms";
import Search from "./components/search/search";
import StoryOrder from "./pages/StoryOrder/StoryOrder";
import UserStore from "./pages/UserStore/UserStore";
import UserStoreData from "./pages/UserStore/UserStoreData/UserStoreData";
import UserStoreBooks from "./pages/UserStore/UserStoreBooks/UserStoreBooks";
import NewBook from "./pages/UserStore/NewBook/NewBook";
import EditBook from "./pages/UserStore/EditBook/EditBook";
import UserStoreStory from "./pages/UserStore/UserStoreStory/UserStoreStory";
import NotFound from "./pages/NotFound/NotFound";
import PublicOferta from "./pages/static/PublicOferta/PublicOferta";
import Redeem from "./pages/Redeem/Redeem";
import StoryOrderPay from "./pages/StoryOrderPay/StoryOrderPay";
import OrderPay from "./pages/OrderPay/OrderPay";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import SuccessfulOrder from "./pages/SuccessfulOrder/SuccessfulOrder";

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="/enter" element={<Enter />} />
          <Route path="/enter/reset-password" element={<Reset />} />

          <Route path="/user" element={<User />} />
          <Route path="/user/cart" element={<UserCard />} />
          <Route path="/user/order" element={<UserOrder />} />

          <Route path="/books/:bookId" element={<BookPage />} />

          <Route path="/redeem" element={<Redeem />} />
          <Route path="/successful-order" element={<SuccessfulOrder />} />
          <Route path="/pay-order/:tocken" element={<OrderPay />} />

          <Route
            path="/story-order"
            element={
              localStorage.getItem("access") ? (
                <StoryOrder />
              ) : (
                <Navigate to={"/enter"} />
              )
            }
          />
          <Route
            path="/story-order/pay/:id"
            element={
              localStorage.getItem("access") ? (
                <StoryOrderPay />
              ) : (
                <Navigate to={"/enter"} />
              )
            }
          />

          <Route
            path="/user-store"
            element={
              localStorage.getItem("access") ? (
                <UserStore />
              ) : (
                <Navigate to={"/enter"} />
              )
            }
          />
          <Route
            path="/store/:storeId"
            element={
              localStorage.getItem("access") ? (
                <UserStoreData />
              ) : (
                <Navigate to={"/enter"} />
              )
            }
          />
          <Route
            path="/store-story/:storeId"
            element={
              localStorage.getItem("access") ? (
                <UserStoreStory />
              ) : (
                <Navigate to={"/enter"} />
              )
            }
          />
          <Route
            path="/store-books/:storeId"
            element={
              localStorage.getItem("access") ? (
                <UserStoreBooks />
              ) : (
                <Navigate to={"/enter"} />
              )
            }
          />
          <Route
            path="/store-books/:storeId/edit-book/:editId"
            element={
              localStorage.getItem("access") ? (
                <EditBook />
              ) : (
                <Navigate to={"/enter"} />
              )
            }
          />
          <Route
            path="/store-books/:storeId/new-book"
            element={
              localStorage.getItem("access") ? (
                <NewBook />
              ) : (
                <Navigate to={"/enter"} />
              )
            }
          />

          <Route path="/conditions" element={<Conditions />} />
          <Route path="/confidentiality" element={<Confidentiality />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/public-offer" element={<PublicOferta />} />
          <Route path="/search" element={<Search />} />

          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Routing />);
