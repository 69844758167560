import React, { useEffect, useRef, useState } from "react";
import PaginationComponent from "../pagination/pagination";
import axios from "axios";
import "./search.css";
import { BASE_URL } from "../../pages/Enter/Auth/auth";
import PaginationPanel from "../paginationPanel/paginationPanel";
import CategoryMenu from "../categoryMenu/CategoryMenu";
import { Link } from "react-router-dom";

const Search = () => {
  let [data, setData] = useState();
  let [recomends, setRecomends] = useState();
  let [sort, setSort] = useState();
  let [isLimit, setIsLimit] = useState(true);
  let [limit, setLimit] = useState(
    window.localStorage.getItem("pageN")
      ? window.localStorage.getItem("pageN") * 15 + 5
      : 20
  );
  let [pageN, setPageN] = useState(
    window.localStorage.getItem("pageN")
      ? window.localStorage.getItem("pageN")
      : 1
  );
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    axios
      .get(BASE_URL + "books/", {
        params: {
          limit: limit,
          id: 0,
          sort: localStorage.getItem("sort"),
          search: searchParams.get("search"),
          categories: localStorage.getItem("cat"),
        },
      })
      .then((data) => {
        if (data.data.bookCards.length < limit || limit <= 0) {
          setIsLimit(false);
        } else {
          setIsLimit(true);
        }
        if (data.data.bookCards == null) {
          localStorage.removeItem("s");
          localStorage.removeItem("cat");
        }
        setData(data.data);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  }, [sort, limit]);

  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
    setLimit(pageN * 15 + 5);
    axios
      .get(BASE_URL + "books/", { params: { limit: 10, id: 0, sort: sort } })
      .then((data) => {
        setRecomends(data.data);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  }, []);

  const addSort = (value) => {
    setSort(value);
    localStorage.setItem("sort", value);
  };
  const nextPage = () => {
    if (isLimit) {
      localStorage.setItem("scrollY", window.scrollY);
      localStorage.setItem("pageN", Math.trunc((limit - 5 + 15) / 15));
      setLimit(limit + 15);
    }
  };
  return (
    <div className="search-page container">
      {data && data.bookCards ? (
        <>
          <div className="catalog-search pag-comp">
            <PaginationComponent data={data} addSort={addSort} />
            {isLimit && <PaginationPanel nextPage={nextPage} />}
          </div>
          <div className="category-menu">
            <CategoryMenu />
          </div>
        </>
      ) : (
        <div className="empty">
          <p className="text-empty">Результаты поиска</p>
          <div className="text-empty-res">
            Извините, по вашему запросу "{searchParams.get("search")}" ничего не
            найдено
          </div>
          <p className="text-empty">
            <b>Возможно, вам понравится:</b>
            <Link
              className="link-back"
              onClick={() => {
                localStorage.removeItem("s");
                localStorage.removeItem("cat");
                window.location.assign("/");
              }}
              to={"/"}
            >
              Перейти на главную
            </Link>
          </p>
          <PaginationComponent data={recomends} addSort={addSort} grid={5} />
        </div>
      )}
    </div>
  );
};

export default Search;
