import axios from "axios";
import { useEffect, useState } from "react";
import "../../App.css";
import "./user.css";
import { BASE_URL } from "../Enter/Auth/auth";
import { send_request } from "../Enter/Auth/setAuthToken";
import moment, { now } from "moment";
import { Link } from "react-router-dom";
import cartImg from "../../assets/my-cart.svg";
import orderImg from "../../assets/my-orders.svg";
import shopImg from "../../assets/my-stores.svg";
import { ClientJS } from "clientjs";
import visible from "../../assets/видимый.png";
import notvisible from "../../assets/скрытый.png";

const User = () => {
  let [isHide, setHide] = useState(true);
  let [isHideNewPassword, setHideNewPassword] = useState(true);

  let [data, setData] = useState();
  const nowDate = new Date();
  let yers = [];

  let selectDate = {
    day:
      data && data.birthDate
        ? new Date(data.birthDate).getDate()
        : nowDate.getDate(),
    month:
      data && data.birthDate
        ? new Date(data.birthDate).getMonth()
        : nowDate.getMonth(),
    year:
      data && data.birthDate
        ? new Date(data.birthDate).getFullYear()
        : nowDate.getFullYear(),
  };
  let [dayInMonth, setDayInMonth] = useState(
    33 - new Date(selectDate.year, selectDate.month, 33).getDate()
  );
  for (let i = 0; i < 100; i++) {
    yers.push(nowDate.getFullYear() - i);
  }
  let days = [];
  for (let i = 1; i < dayInMonth + 1; i++) {
    days.push(i);
  }
  let [countries, setCountries] = useState();
  let [statusDataUdate, setStatusDataUpdate] = useState();
  let [statusPassUdate, setStatusPassUpdate] = useState();
  let [isUserStore, setUserStore] = useState();
  let [errorData, setErrorData] = useState({
    address: "",
    birthDate: "",
    countryID: "",
    email: "",
    firstname: "",
    lastname: "",
    phoneNumber: "",
    username: "",
  });
  let [password, setPassword] = useState({
    password: "",
    newPassword: "",
  });
  let [errPassword, setErrPassword] = useState({
    password: "",
    newPassword: "",
  });

  useEffect(() => {
    localStorage.removeItem("scrollYBooks");
    localStorage.removeItem("limitbook");
    send_request
      .get(BASE_URL + "user/stores")
      .then((res) => {
        setUserStore(res.data.stores);
      })
      .catch((err) => {
        console.log(err);
      });
    send_request
      .get(BASE_URL + "user")
      .then((data) => {
        setData(data.data.user);
      })
      .catch((err) => {
        window.location.assign("/enter");
        console.log(err);
      });

    axios
      .get(BASE_URL + "countries")
      .then((res) => {
        setCountries(res.data.countries);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  }, []);

  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, [isUserStore]);

  useEffect(() => {
    data && setErrorData(validate(data));
    password && setErrPassword(validatePass(password));
  }, [data, password]);

  const validatePass = (values) => {
    let errors = {};
    if (values.password.length < 8) {
      errors.password = "Поле должно содержать не менее 8 символов";
    }
    if (values.newPassword.length < 8) {
      errors.newPassword = "Поле должно содержать не менее 8 символов";
    }
    return errors;
  };

  const validate = (values) => {
    let errors = {};

    if (values.username.length < 1) {
      errors.username = "Поле должно содержать не менее 1 символа";
    } else if (values.username.length > 20) {
      errors.username = "Поле должно содержать не более 20 символов";
    }

    if (!values.email) {
      errors.email = "Обязательное поле";
    }
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/.test(values.email) === false) {
      errors.email = "Поле заполнено неверно";
    }

    if (
      values.phoneNumber &&
      /^\+[1-9]\d{10,14}$/.test(values.phoneNumber) === false
    ) {
      errors.phoneNumber = "Поле должно иметь вид (+123...)";
    }

    return errors;
  };

  const changeInputUser = (event) => {
    event.persist();
    setStatusDataUpdate("");
    setData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const changeInputPassword = (event) => {
    event.persist();
    setStatusPassUpdate("");
    setPassword((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();

  const setSignOut = () => {
    axios
      .post(
        BASE_URL + "signout",
        {
          useragent: window.navigator.userAgent,
          fingerprint: fingerprint.toString(),
        },
        { withCredentials: true }
      )
      .then((res) => {
        localStorage.removeItem("access");
        localStorage.removeItem("user");
        localStorage.removeItem("country");
        localStorage.removeItem("orderID");
        if (res.status == 200) {
          window.location.assign("/");
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
        console.log(err);
      });
  };

  const setUserData = () => {
    data.countryID = +data.countryID;
    send_request
      .patch(BASE_URL + "user", data)
      .then((res) => {
        if (res.status === 200) {
          setStatusDataUpdate("Данные успешно обновлены");
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setStatusDataUpdate("Изменения уже внесены");
        } else {
          setStatusDataUpdate("Обновить не получилось");
        }
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };

  const changePassword = () => {
    send_request
      .patch(BASE_URL + "user/password", password)
      .then((res) => {
        if (res.status === 200) {
          setStatusPassUpdate("Пароль успешно изменен");
          window.document.getElementById("user-password").value = "";
          window.document.getElementById("new-password").value = "";
          setPassword(() => {
            return {
              password: "",
              newPassword: "",
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };
  return (
    <>
      {data && (
        <div className="user container">
          <div className="handler">
            <h2>Учетная запись</h2>
          </div>

          <div className="block-user">
            <div>
              <div className="btn-user">
                <img src={cartImg} alt="cart" />
                <Link to="cart">Моя корзина</Link>
              </div>
              <div className="btn-user">
                <img src={orderImg} alt="order" />
                <Link to="/story-order">Мои заказы</Link>
              </div>
              {isUserStore && (
                <div className="btn-user">
                  <img src={shopImg} alt="shop" />
                  <Link to="/user-store">Мои магазины</Link>
                </div>
              )}
            </div>
            <div className="block-user">
              <h3>Личная информация</h3>
              <input
                name="username"
                placeholder="Логин"
                type="text"
                defaultValue={data && data.username}
                onChange={changeInputUser}
              />
              {errorData.username && (
                <div className="err">{errorData.username}</div>
              )}
              <input
                name="firstname"
                placeholder="Имя"
                type="text"
                defaultValue={data && data.firstname}
                onChange={changeInputUser}
              />
              <input
                name="lastname"
                placeholder="Фамилия"
                type="text"
                defaultValue={data && data.lastname}
                onChange={changeInputUser}
              />
              {errorData.lastname && (
                <div className="err">{errorData.lastname}</div>
              )}
              <input
                name="email"
                placeholder="Email"
                type="email"
                readOnly
                value={data && data.email}
              />
              {errorData.email && <div className="err">{errorData.email}</div>}

              <input
                name="phoneNumber"
                placeholder="Номер телефона"
                type="text"
                defaultValue={data && data.phoneNumber}
                onChange={changeInputUser}
              />
              {errorData.phoneNumber && (
                <div className="err">{errorData.phoneNumber}</div>
              )}
              {countries && (
                <select
                  name="countryID"
                  placeholder="Страна"
                  className="select-country"
                  // type='text'
                  defaultValue={data && data.countryID}
                  onChange={changeInputUser}
                >
                  <option value={""}>Страна</option>
                  {countries.map((i, ind) => (
                    <option key={ind} value={+i.id}>
                      {i.country}
                    </option>
                  ))}
                </select>
              )}
              <input
                name="address"
                placeholder="Почтовый адрес"
                type="text"
                defaultValue={data && data.address}
                onChange={changeInputUser}
              />

              <p className="label-date">Дата рождения</p>
              <input
                type="date"
                defaultValue={
                  data && data.birthDate
                    ? moment(data.birthDate).format("YYYY-MM-DD")
                    : null
                }
                onChange={(e) => {
                  data.birthDate = new Date(`${e.target.value}T00:00:00.000Z`);
                }}
                max={moment(nowDate).format("YYYY-MM-DD")}
              />

              {statusDataUdate && (
                <div className="status-change">{statusDataUdate}</div>
              )}

              <button
                className="btn"
                disabled={Object.keys(errorData).length !== 0 ? true : false}
                onClick={setUserData}
              >
                Обновить данные
              </button>
            </div>
            <div className="change-pass">
              <h3>Смена пароля</h3>
              <label>Текущий пароль</label>
              <div className="visible-block">
                <input
                  name="password"
                  id="user-password"
                  placeholder="пароль"
                  type={isHide ? "password" : "text"}
                  defaultValue={statusPassUdate && ""}
                  onChange={changeInputPassword}
                />
                <img
                  onClick={() => setHide(!isHide)}
                  src={!isHide ? visible : notvisible}
                  alt="visible"
                />
              </div>
              {errPassword.password && (
                <div className="error">{errPassword.password}</div>
              )}
              <label>
                <br />
                Новый пароль
              </label>
              <div className="visible-block">
                <input
                  name="newPassword"
                  id="new-password"
                  placeholder="новый пароль"
                  type={isHideNewPassword ? "password" : "text"}
                  defaultValue={statusPassUdate && ""}
                  onChange={changeInputPassword}
                />
                <img
                  onClick={() => setHideNewPassword(!isHideNewPassword)}
                  src={!isHideNewPassword ? visible : notvisible}
                  alt="visible"
                />
              </div>
              {errPassword.newPassword && (
                <div className="error">{errPassword.newPassword}</div>
              )}
              {statusPassUdate && (
                <div className="status-change">{statusPassUdate}</div>
              )}
              <button className="btn" onClick={changePassword}>
                Сменить
              </button>
            </div>
          </div>
          <button className="btn" onClick={setSignOut}>
            Выйти из аккаунта
          </button>
        </div>
      )}
    </>
  );
};

export default User;
