import "./err-page.css";
import Img500 from "../../assets/500.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const ErrorPage = () => {
  useEffect(() => {
    localStorage.removeItem("sort");
    localStorage.removeItem("s");
    localStorage.removeItem("cat");
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, []);

  return (
    <div className="not-found error-page container">
      <img src={Img500} alt="not found" />
      <Link to={"/"}>Перейти на главную</Link>
    </div>
  );
};

export default ErrorPage;
