import { useEffect, useState } from "react";
import "../../App.css";
import "./user-store.css";
import { BASE_URL } from "../Enter/Auth/auth";
import { send_request } from "../Enter/Auth/setAuthToken";
import { Link } from "react-router-dom";

const UserStore = () => {
  let [stores, setStores] = useState();

  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
    localStorage.removeItem("scrollYBooks");
    localStorage.removeItem("limitbook");
    send_request
      .get(BASE_URL + "user/stores")
      .then((res) => setStores(res.data.stores))
      .catch((err) => {
        if (err.response.status == 400) {
          window.location.assign("/not-found");
        }
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  }, []);
  return (
    <div className="user-store container">
      {stores &&
        stores.map((i, ind) => (
          <div className="cart" key={ind}>
            <h2>{i.brand}</h2>
            <div className="user-store-option">
              <div className="btn">
                <Link to={"/store/" + i.id}>Данные о магазине</Link>
              </div>
              <div className="btn">
                <Link to={"/store-books/" + i.id}>Книги магазина</Link>
              </div>
              <div className="btn">
                <Link to={"/store-story/" + i.id}>Заказы</Link>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default UserStore;
