const initialState = {
    cartInfo: null,
    textSearch:''
  };
  
  export default function TestReducer(state = initialState, action) {
    switch (action.type) {
      case "DO_THIS":
        return {
          ...state,
          cartInfo: action.payload,
        };
      case "TEXT_SEARCH":
        return {
          ...state,
          textSearch: action.payload,
        };
      default:
        return state;
    }
  }