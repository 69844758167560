import axios from "axios";
import { useEffect, useState } from "react";
import "./user-store.css";
import CategoryMenu from "../../../components/categoryMenu/CategoryMenu";
import PaginationComponent from "../../../components/pagination/pagination";
import { BASE_URL } from "../../Enter/Auth/auth";
import { send_request } from "../../Enter/Auth/setAuthToken";

const UserStoreData = () => {
  let [countries, setCountries] = useState();
  let [status, setStatus] = useState();
  let [statusDataUdate, setStatusDataUpdate] = useState();

  let [data, setData] = useState({
    brand: "",
    legalAddress: "",
    iban: "",
    legalEmail: "",
    countryID: 0,
  });
  let [errorData, setErrorData] = useState({
    brand: "",
    legalAddress: "",
    iban: "",
    legalEmail: "",
    countryID: "",
  });
  let storeId = window.location.pathname.split("/").pop();
  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
    send_request
      .get(BASE_URL + "user/stores")
      .then((res) => {
        setData(res.data.stores.find((i) => i.id == storeId));
      })
      .catch((err) => {
        if (err.response.status == 400) {
          window.location.assign("/not-found");
        } else if (err.response.status == 409) {
          setStatus(
            "Это название магазина уже используется. Попробуете другое!"
          );
        }
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });

    axios
      .get(BASE_URL + "countries")
      .then((res) => {
        setCountries(res.data.countries);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
    send_request
      .get(BASE_URL + "user/stores")
      .then()
      .catch((err) => {
        if (err.response.status == 400) {
          window.location.assign("/not-found");
        }
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  }, []);
  useEffect(() => {
    data && setErrorData(validate(data));
  }, [data]);

  const validate = (values) => {
    let errors = {};
    if (values.brand < 1) {
      errors.brand = "Обязательно поле";
    }
    if (values.legalAddress < 1) {
      errors.legalAddress = "Обязательно поле";
    }
    if (values.iban < 1) {
      errors.iban = "Обязательно поле";
    } else if (
      /^[A-Z]{2}[0-9]{2}[A-Z0-9]{11,30}$/.test(values.iban) === false
    ) {
      errors.iban = "Поле заполнено неверно";
    }
    if (!values.legalEmail) {
      errors.legalEmail = "Обязательное поле";
    } else if (
      /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/.test(values.legalEmail) === false
    ) {
      errors.legalEmail = "Поле заполненно неверно";
    }
    return errors;
  };

  const setDataStore = () => {
    send_request
      .patch(BASE_URL + "user/stores/" + storeId, data)
      .then(() => {
        setStatusDataUpdate("Данные успешно обновлены");
      })
      .catch((err) => {
        if (err.response.status == 409) {
          setStatus(
            "Это название магазина уже используется. Попробуете другое!"
          );
        }
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };

  const changeInputUser = (event) => {
    event.persist();
    setStatusDataUpdate(null);
    setData((prev) => {
      return {
        ...prev,
        [event.target.name]:
          event.target.name == "countryID"
            ? +event.target.value
            : event.target.value,
      };
    });
  };
  return (
    <div className="cart user-store container">
      <h2>Данные о магазине</h2>
      {status && <div className="err-enter">{status}</div>}
      <input
        name="brand"
        placeholder="Название магазина"
        type="text"
        defaultValue={data && data.brand}
        onChange={changeInputUser}
        onFocus={changeInputUser}
      />
      {errorData.brand && <div className="err">{errorData.brand}</div>}
      <input
        name="legalAddress"
        placeholder="Юридический адрес"
        type="text"
        defaultValue={data && data.legalAddress}
        onChange={changeInputUser}
        onFocus={changeInputUser}
      />
      {errorData.legalAddress && (
        <div className="err">{errorData.legalAddress}</div>
      )}
      <input
        name="iban"
        placeholder="IBAN"
        type="text"
        defaultValue={data && data.iban}
        onChange={changeInputUser}
        onFocus={changeInputUser}
      />
      {errorData.iban && <div className="err">{errorData.iban}</div>}
      <input
        name="legalEmail"
        placeholder="Email для связи с покупателями"
        type="email"
        defaultValue={data && data.legalEmail}
        onChange={changeInputUser}
        onFocus={changeInputUser}
      />
      {errorData.legalEmail && (
        <div className="err">{errorData.legalEmail}</div>
      )}
      {countries && (
        <select
          name="countryID"
          placeholder="Страна"
          className="select-country"
          defaultValue={data && +data.countryID}
          value={data && +data.countryID}
          onChange={changeInputUser}
          onFocus={changeInputUser}
        >
          {countries.map((i, ind) => (
            <option key={ind} value={+i.id}>
              {i.country}
            </option>
          ))}
        </select>
      )}
      <div>
        {statusDataUdate && (
          <div className="status-change">{statusDataUdate}</div>
        )}
        <button onClick={setDataStore} className="btn">
          Обновить данные о магазине
        </button>
      </div>
    </div>
  );
};

export default UserStoreData;
