import "./redeem.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../Enter/Auth/auth";
import imgKey from "../../assets/ключ успешной смены пароля.png";
import visible from "../../assets/видимый.png";
import notvisible from "../../assets/скрытый.png";

const Redeem = () => {
  let [status, setStatus] = useState(true);
  let [isHide, setHide] = useState(true);
  const [reset, setResetDate] = useState(() => {
    return {
      password: "",
    };
  });
  const [resetErr, setResetErr] = useState(() => {
    return {
      password: "",
    };
  });

  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();

    axios
      .get(BASE_URL + "redeem", {
        params: { token: window.location.search.split("=")[1] },
      })
      .then((res) => {})
      .catch((err) => window.location.assign("/not-found"));
  }, []);
  useEffect(() => {
    reset && setResetErr(validate(reset));
  }, [reset]);

  const changeInputEntry = (event) => {
    event.persist();
    setResetDate((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const validate = (values) => {
    let errors = {};
    if (values.password.length < 8) {
      errors.password = "Поле должно содержать не менее 8 символов";
    }
    return errors;
  };
  const setReset = () => {
    axios
      .post(
        BASE_URL + "redeem/password",
        { newPassword: reset.password },
        { params: { token: window.location.search.split("=")[1] } }
      )
      .then((res) => {
        setStatus(false);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };

  return (
    <div className="redeem cart container">
      {status ? (
        <>
          <h2>Укажите новый пароль для вашего аккаунта</h2>
          <div className="visible-block">
            <input
              name="password"
              type={isHide ? "password" : "text"}
              placeholder="Придумайте новый пароль"
              onChange={changeInputEntry}
            />
            <img
              onClick={() => setHide(!isHide)}
              src={!isHide ? visible : notvisible}
              alt="visible"
            />
          </div>
          {resetErr.password && <div className="err">{resetErr.password}</div>}
          <button
            className="btn"
            disabled={Object.keys(resetErr).length !== 0 ? true : false}
            onClick={setReset}
          >
            Изменить пароль
          </button>
        </>
      ) : (
        <>
          <img className="access-key" src={imgKey} alt="key" />
          <h2>Вы удачно сменили пароль!</h2>
          <Link className="link-back" to={"/"}>
            Перейти на главную
          </Link>
        </>
      )}
    </div>
  );
};

export default Redeem;
