import "./successfulorder.css";
import ImgSuccessful from "../../assets/заказ оформлен.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const SuccessfulOrder = () => {
  useEffect(() => {
    localStorage.removeItem("sort");
    localStorage.removeItem("s");
    localStorage.removeItem("cat");
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, []);

  return (
    <div className="successful-order container">
      <img src={ImgSuccessful} alt="Successful" />
      <Link to={"/"}>Перейти на главную</Link>
    </div>
  );
};

export default SuccessfulOrder;
