import { useEffect, useState } from "react";
import "../../App.css";
import { send_request } from "../../pages/Enter/Auth/setAuthToken";
import { BASE_URL } from "../../pages/Enter/Auth/auth";
import trash from "../../assets/trash.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getInfoCart } from "../../store/Actions/Action";

const Order = ({ getCart, cartUser }) => {
  let [cart, setCart] = useState(cartUser);
  let [shipPrice, setShipPrice] = useState();
  let arrBooks = JSON.parse(localStorage.getItem("cart"));
  const dispatch = useDispatch();

  useEffect(() => {
    cart.stores.map((i) => {
      if (i.shippingPrice !== null) {
        if (isNaN(shipPrice)) {
          setShipPrice(0);
        }
        setShipPrice(shipPrice + i.shippingPrice);
      }
    });
  }, [cartUser]);

  useEffect(() => {
    setCart(cartUser);
  }, [cartUser]);

  return (
    <div>
      <div className="handler-cart">
        <h2>Товары</h2>
      </div>
      <div className="user-order__stores">
        {cart && cart.stores ? (
          <div>
            {cart.stores.map((i, index) => (
              <div key={index}>
                <div className="brand">{i.brand}</div>
                <table>
                  {i.books.map((item, ind) => (
                    <tr key={ind}>
                      <td className="img-prod">
                        <Link to={"/books/" + item.id}>
                          <img src={item.image} alt={item.title} />
                        </Link>
                      </td>
                      <td className="description-prod">
                        <h3>
                          <Link to={"/books/" + item.id}>{item.title}</Link>
                        </h3>
                        <p>
                          <b>ID:</b> {item.id}
                        </p>
                        <p>
                          <b>Вес:</b> {item.weight} гр.
                        </p>
                        {item.message && <p>{item.message}</p>}
                      </td>
                      <td className="quantity-prod">
                        <div className="book-quantity">
                          {item.quantity > 1 && (
                            <div
                              onClick={() => {
                                if (localStorage.getItem("access")) {
                                  item.quantity > 1 &&
                                    send_request
                                      .patch(
                                        BASE_URL + "user/cart/" + item.id,
                                        { quantity: item.quantity - 1 }
                                      )
                                      .then(() => {
                                        getCart();
                                      })
                                      .catch((err) => {
                                        if (err.response.status === 500) {
                                          window.location.assign("/error");
                                        }
                                      });
                                } else {
                                  if (item.quantity > 1) {
                                    let indBook = arrBooks.indexOf(
                                      arrBooks.find(
                                        (book) => book.id == item.id
                                      )
                                    );
                                    arrBooks[indBook].quantity =
                                      arrBooks[indBook].quantity - 1;
                                    localStorage.setItem(
                                      "cart",
                                      JSON.stringify(arrBooks)
                                    );
                                    getCart();
                                  }
                                }
                              }}
                            >
                              -
                            </div>
                          )}
                          <div>{item.quantity}</div>
                          {item.quantity < item.availableQuantity && (
                            <div
                              onClick={() => {
                                if (localStorage.getItem("access")) {
                                  item.quantity < item.availableQuantity &&
                                    send_request
                                      .patch(
                                        BASE_URL + "user/cart/" + item.id,
                                        { quantity: item.quantity + 1 }
                                      )
                                      .then(() => {
                                        getCart();
                                      })
                                      .catch((err) => {
                                        if (err.response.status === 500) {
                                          window.location.assign("/error");
                                        }
                                      });
                                } else {
                                  if (item.quantity < item.availableQuantity) {
                                    let indBook = arrBooks.indexOf(
                                      arrBooks.find(
                                        (book) => book.id == item.id
                                      )
                                    );
                                    arrBooks[indBook].quantity += 1;
                                    localStorage.setItem(
                                      "cart",
                                      JSON.stringify(arrBooks)
                                    );
                                    getCart();
                                  }
                                }
                              }}
                            >
                              +
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="price-prod">{item.price.toFixed(2)} €</td>
                      <td className="delete-prod">
                        <img
                          src={trash}
                          alt="delete"
                          onClick={() => {
                            if (localStorage.getItem("access")) {
                              send_request
                                .delete(BASE_URL + "user/cart/" + item.id)
                                .then((res) => {
                                  dispatch(getInfoCart());
                                  if (i.books.length < 2) {
                                    window.location.assign("/user/cart");
                                  } else {
                                    getCart();
                                  }
                                })
                                .catch((err) => {
                                  if (err.response.status === 500) {
                                    window.location.assign("/error");
                                  }
                                });
                            } else {
                              arrBooks.splice(
                                arrBooks.indexOf(
                                  arrBooks.find((book) => book.id == item.id)
                                ),
                                1
                              );
                              localStorage.setItem(
                                "cart",
                                JSON.stringify(arrBooks)
                              );
                              if (i.books.length < 2) {
                                window.location.assign("/user/cart");
                              } else {
                                getCart();
                              }
                              dispatch(getInfoCart());
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            ))}
            <div className="all-panel">
              <div>Всего товаров:</div>
              <div>{cart.totalBooksPrice.toFixed(2)} €</div>
            </div>
            <div className="all-panel">
              <div>Доставка:</div>
              <div>{cart.totalShippingPrice.toFixed(2) + " €"}</div>
            </div>
            <div className="all-panel">
              <h3>Цена:</h3>
              <div>{cart.totalPrice.toFixed(2) + " €"}</div>
            </div>
          </div>
        ) : (
          <div>Здесь пока ничего нет</div>
        )}
      </div>
    </div>
  );
};

export default Order;
