import axios from "axios";
import { useEffect, useState } from "react";
import "./signup.css";
import { BASE_URL } from "../Auth/auth";
import { ClientJS } from "clientjs";
import visible from "../../../assets/видимый.png";
import notvisible from "../../../assets/скрытый.png";

const SignUp = () => {
  let [isAllredy, setAllredy] = useState(false);
  let [isHide, setHide] = useState(true);

  const client = new ClientJS();
  const fingerprint = client.getFingerprint();
  const [registration, setRegistration] = useState(() => {
    return {
      username: "",
      email: "",
      password: "",
      useragent: window.navigator.userAgent,
      fingerprint: fingerprint.toString(),
    };
  });
  const [registrationErr, setRegistrationErr] = useState(() => {
    return {
      username: "",
      email: "",
      password: "",
    };
  });
  const changeInputRegister = (event) => {
    isAllredy && setAllredy(false);
    event.persist();
    setRegistration((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const validate = (values) => {
    let errors = {};
    if (values.username.length < 1) {
      errors.username = "Поле должно содержать не менее 1 символа";
    } else if (values.username.length > 20) {
      errors.username = "Поле должно содержать не более 20 символов";
    }

    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/.test(values.email) === false) {
      errors.email = "Поле заполнено неверно";
    }
    if (values.password.length < 8) {
      errors.password = "Поле должно содержать не менее 8 символов";
    }
    return errors;
  };

  const setSignUp = () => {
    axios
      .post(BASE_URL + "signup", registration, { withCredentials: true })
      .then((res) => {
        localStorage.setItem("access", res.data.accessToken);
        localStorage.removeItem("country");
        localStorage.removeItem("cart");
        window.location.assign("/user");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setAllredy(true);
        }
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };

  useEffect(() => {
    registration && setRegistrationErr(validate(registration));
  }, [registration]);
  return (
    <div className="sign-up">
      <h3>Создать аккаунт</h3>
      {isAllredy && (
        <div className="err-enter">
          Пользователь с таким email уже существует
        </div>
      )}

      <div className="form-signup">
        <input
          name="username"
          placeholder="Логин"
          type="text"
          onChange={changeInputRegister}
        />
        {registrationErr.username && (
          <div className="err">{registrationErr.username}</div>
        )}

        <input
          name="email"
          placeholder="Email"
          type="email"
          onChange={changeInputRegister}
        />
        {registrationErr.email && (
          <div className="err">{registrationErr.email}</div>
        )}
        <div className="visible-block-enter">
          <input
            name="password"
            placeholder="Пароль"
            type={isHide ? "password" : "text"}
            onChange={changeInputRegister}
          />
          <img
            onClick={() => setHide(!isHide)}
            className="visible-status"
            src={!isHide ? visible : notvisible}
            alt="visible"
          />
        </div>
        {registrationErr.password && (
          <div className="err">{registrationErr.password}</div>
        )}
      </div>
      <button
        className="btn"
        disabled={Object.keys(registrationErr).length !== 0 ? true : false}
        onClick={setSignUp}
      >
        Зарегистрироваться
      </button>
    </div>
  );
};

export default SignUp;
