import { useEffect, useState } from "react";
import "./categoryMenu.css";
import axios from "axios";
import Accordion from "../accordion/accordion";
import { BASE_URL } from "../../pages/Enter/Auth/auth";

function CategoryMenu() {
  let [categores, setCategores] = useState();
  let activeCat = localStorage.getItem("cat");
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    axios.get(BASE_URL + "categories").then((res) => {
      setCategores(res);
    });
  }, []);

  const setSearchParam = () => {
    localStorage.removeItem("scrollY");
    localStorage.removeItem("pageN");
    if (searchParams.get("search")) {
      window.location.assign("/search?search=" + searchParams.get("search"));
    } else {
      window.location.assign("/");
    }
  };
  return (
    <div>
      <h3 className="handler-cat-menu">Каталог</h3>

      <div className="cat-menu">
        {categores &&
          categores.data &&
          categores.data.categories.map((i, index) => (
            <div
              className={"accordion__title"}
              key={index}
              onClick={() => {
                if (!i.subcategories) {
                  localStorage.setItem("cat", i.id);
                  setSearchParam();
                }
              }}
            >
              {i.subcategories ? (
                <Accordion
                  active={
                    i.subcategories.find(
                      (categ) => categ.id === +localStorage.getItem("cat")
                    )
                      ? true
                      : i.subcategories.find((categ) =>
                          categ.subcategories
                            ? Boolean(
                                categ.subcategories.find(
                                  (subcateg) =>
                                    subcateg.id === +localStorage.getItem("cat")
                                )
                              )
                            : false
                        )
                  }
                  title={
                    <div
                      className={
                        activeCat == i.id ? "active-cat item-menu" : "item-menu"
                      }
                      onClick={() => {
                        localStorage.setItem("cat", i.id);
                        setSearchParam();
                      }}
                    >
                      {i.category}
                    </div>
                  }
                  content={i.subcategories.map((item, ind) =>
                    item.subcategories ? (
                      <Accordion
                        key={item.id}
                        active={item.subcategories.find(
                          (subcateg) =>
                            subcateg.id === +localStorage.getItem("cat")
                        )}
                        title={
                          <div
                            className={
                              activeCat == item.id
                                ? "active-cat item-menu"
                                : "item-menu"
                            }
                            onClick={() => {
                              localStorage.setItem("cat", item.id);
                              setSearchParam();
                            }}
                          >
                            {item.category}
                          </div>
                        }
                        content={item.subcategories.map((subcat, num) => (
                          <div
                            key={subcat.id}
                            className={
                              activeCat == subcat.id
                                ? "active-cat item-menu subcat-menu"
                                : "item-menu subcat-menu"
                            }
                            onClick={() => {
                              localStorage.setItem("cat", subcat.id);
                              setSearchParam();
                            }}
                          >
                            {subcat.category}
                          </div>
                        ))}
                      />
                    ) : (
                      <div
                        key={item.id}
                        className={
                          activeCat == item.id
                            ? "active-cat item-menu"
                            : "item-menu"
                        }
                        onClick={() => {
                          localStorage.setItem("cat", item.id);
                          setSearchParam();
                        }}
                      >
                        {item.category}
                      </div>
                    )
                  )}
                />
              ) : (
                <div
                  key={i.id}
                  className={
                    activeCat == i.id ? "active-cat item-menu" : "item-menu"
                  }
                  onClick={() => {
                    localStorage.setItem("cat", i.id);
                    setSearchParam();
                  }}
                >
                  {i.category}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default CategoryMenu;
