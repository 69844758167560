import { useEffect, useState } from "react";
import "./confidentiality.css";

const Confidentiality = () => {
  useEffect(() => {
    localStorage.removeItem("s");
    localStorage.removeItem("cat");
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, []);
  return (
      <div className="confidentiality container">
          <h2>УВЕДОМЛЕНИЕ О КОНФИДЕНЦИАЛЬНОСТИ И ЗАЩИТЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
          <p>01/08/2023</p>
          <p>
              BESTBOOKSHOP признает важность защиты персональных данных, поэтому ваша
              конфиденциальность очень важна для нас.{" "}
          </p>
          <p>
              Мы разработали данное Уведомление о конфиденциальности и защите
              персональных данных, чтобы вы могли понять, как мы собираем, используем,
              распространяем и изменяем ваши персональные данные.
          </p>

          <h3>§1 ОБЩИЕ ПОЛОЖЕНИЯ</h3>
          <p>
              1.1 Собственником веб-сайта https://bestbookshop.eu и Управляющим данными
              является Premier Logistic OÜ, регистрационный номер: 11386669 (далее –
              BESTBOOKSHOP), юридический адрес: Tõru tn 15, Pirita linnaosa, Tallinn
              Harju maakond, 12011, Estonia.
          </p>
          <p>
              1.2 Покупатель – физическое лицо, которое приобретает товары в
              интернет-магазине BESTBOOKSHOP.
          </p>
          <p>
              1.3 Персональные данные – это все данные, которые позволяют
              идентифицировать вас лично.{" "}
          </p>
          <p>
              1.4 Данное Уведомление действует в отношении обработки ваших
              персональных данных, когда вы как Покупатель и/или Посетитель веб-сайта
              https://bestbookshop.eu регистрируетесь, производите покупку, связываетесь
              с нашей компанией через каналы коммуникации (электронная почта, телефон,
              чаты и обмен бумажными документами).
          </p>
          <p>
              1.5 Уведомление о конфиденциальности и защите персональных данных
              содержит сведения о целях обработки персональных данных, лицах, которым
              предоставляется доступ к таким данным, и ваших правах в отношении ваших
              персональных данных.
          </p>
          <p>
              1.6 Управляющий данными принимает решение о целях и способах обработки
              персональных данных, гарантирует конфиденциальность сбора, обработки и
              сохранения персональных данных Покупателя, что определяется Законом о
              защите персональных данных Эстонии. Настоящий Закон регулирует защиту
              физических лиц при обработке персональных данных в той мере, в какой он
              уточняет и дополняет положения, содержащиеся в Общем регламенте защиты
              персональных данных (GDPR) (ЕС) 2016/679 Европейского парламента и
              Совета о защите физических лиц при обработке персональных данных и
              свободном перемещении таких данных и Директиве 95/46/ЕС об отмене (Общее
              положение о защите персональных данных) (ОЖ L 119, 04.05.2016, стр.
              1–88).{" "}
          </p>
          <p>
              1.7 В целях безопасности и для защиты передачи личных данных и другого
              конфиденциального контента (например, заказов или запросов
              ответственному лицу) этот веб-сайт использует SSL или TLS-шифрование. Вы
              можете распознать зашифрованное соединение по строке символов «https://»
              и символу блокировки в строке браузера.
          </p>
          <p>
              1.8 Используя веб-сайт BESTBOOKSHOP, вы соглашаетесь с данным Уведомлением
              о конфиденциальности и защите персональных данных.
          </p>

          <h3>§2 ОБРАБАТЫВАЕМЫЕ КАТЕГОРИИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
          <p>
              2.1 При регистрации на сайте BESTBOOKSHOP обрабатывает следующие категории персональных данных: ─
              имя и фамилия; ─ контактные данные (адрес, номер телефона/мобильного
              телефона, адрес электронной почты); ─ сведения об оплате (документ,
              подтверждающий оплату и тип оплаты); ─ история покупок; ─ данные о сайте
              на основании вашего IP-адреса; ─ сведения о браузере (версия браузера);
              ─ файлы сookie.
          </p>

          <h3>§3 СБОР ДАННЫХ ПРИ ПОСЕЩЕНИИ ВЕБ-САЙТА</h3>
          <p>
              3.1 Если вы используете наш веб-сайт только в информационных целях, без
              регистрации или иным образом не предоставляете нам информацию, мы
              собираем только те данные, которые ваш браузер передает на наш сервер
              (так называемые «файлы журнала сервера»).{" "}
          </p>
          <p>
              3.2 Когда вы посещаете наш веб-сайт, мы собираем следующие данные,
              которые технически необходимы нам для отображения веб-сайта для вас: ─
              наш посещенный сайт; ─ дата и время на момент доступа; ─ количество
              отправленных данных в байтах; ─ источник/ссылка, с которой вы пришли на
              страницу; ─ используемый браузер; ─ используемая операционная система; ─
              используемый IP-адрес (при необходимости: в анонимной форме).
          </p>
          <p>
              3.3 Обработка осуществляется в соответствии со статьей 6, пунктом 1,
              буквой f Общего регламента по защите данных (GDPR), на основании нашей
              законной заинтересованности в повышении стабильности и функциональности
              нашего веб-сайта. Данные не будут переданы или использованы каким-либо
              другим образом. Тем не менее, мы оставляем за собой право впоследствии
              проверять файлы журнала сервера, если есть конкретные признаки
              незаконного использования.
          </p>

          <h3>§4 СБОР ДАННЫХ ПРИ РЕГИСТРАЦИИ НА САЙТЕ</h3>
          <p>
              4.1 В соответствии со статьей 6, пункт 1, буква b GDPR, персональные
              данные будут собираться и обрабатываться в объеме, необходимом в каждом
              конкретном случае, если вы предоставите их нам при регистрации на сайте.
              Удаление вашей учетной записи пользователя возможно в любое время и
              может быть сделано путем отправки сообщения на электронную почту:{" "}
              <a href="mailto:support@bestbookshop.eu">support@bestbookshop.eu</a>.
              После того, как ваша учетная запись будет удалена, ваши данные будут
              удалены при условии, что все заключенные договоры выполнены, не
              существует законных периодов хранения, противоречащих этому, и у нас нет
              законного интереса к дальнейшему хранению.
          </p>

          <h3>§5 СБОР ДАННЫХ ПРИ ОБРАЩЕНИИ </h3>
          <p>
              5.1 При обращении к нам (например, через контактную форму или
              электронную почту) персональные данные будут обрабатываться
              исключительно с целью обработки и ответа на ваш запрос и только в
              объеме, необходимом для этого. Правовой основой для обработки этих
              данных является наша законная заинтересованность в ответе на ваш запрос
              в соответствии со статьей 6 (1) (f) GDPR. Если вы контактируете с нами
              по поводу договора, дополнительным правовым основанием для обработки
              является статья 6 (1) (b) GDPR. Ваши данные будут удалены, если из
              обстоятельств можно будет сделать вывод, что рассматриваемые факты были
              окончательно выяснены, и при условии, что нет никаких юридических
              обязательств по хранению, противоречащих этому.
          </p>
          <p>
              5.2 При обращении к нам по электронной почте или через форму обратной
              связи, конфиденциальность переданных данных не может быть гарантирована.
              Содержание электронных писем может стать доступно третьим лицам. В связи
              с этим мы рекомендуем отправлять конфиденциальную информацию
              исключительно по почте.
          </p>

          <h3>§6 НЕПРЕДОСТАВЛЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
          <p>
              6.1 Чтобы оформить покупку на нашем сайте, вы должны предоставить нам
              свои персональные данные в соответствии с условиями, приведенными в §2.
              Если вы не предоставите нам свои персональные данные, мы не сможем
              исполнить наши договорные обязательства перед вами, идентифицировать вас
              как плательщика и оформить доставку.
          </p>

          <h3>
              §7 ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ И СООТВЕТСТВУЮЩИЕ ЗАКОННЫЕ
              ОСНОВАНИЯ
          </h3>
          <p>
              7.1 BESTBOOKSHOP обрабатывает ваши персональные данные в целях,
              изложенных ниже. Для каждой цели BESTBOOKSHOP должен иметь законное
              основание. Законным основанием может считаться: ─ ваше согласие на
              соответствующую обработку; ─ необходимость обработки данных для
              исполнения договора, стороной которого вы являетесь.
          </p>
          <p>
              7.2 Для выполнения договора в целях доставки и оплаты, собранные нами
              персональные данные будут переданы уполномоченной службе доставки и
              уполномоченному банку в соответствии со статьей 6, пункт 1, пункт b
              GDPR.{" "}
          </p>
          <p>
              7.3 Если законным основанием для какой-либо указанной цели является ваше
              согласие, мы не будем обрабатывать ваши персональные данные для этой
              цели, не получив вашего согласия.{" "}
          </p>
          <p>
              7.4 Вы имеете право в любой момент отозвать согласие, используя ссылки
              для отказа в электронных письмах. Учтите, что отзыв согласия не влияет
              на законность обработки, которая выполнялась на основании такого
              согласия до его отзыва.
          </p>

          <h3>§8 ФАЙЛЫ COOKIE </h3>
          <p>
              8.1 Файлы cookie — это пакеты данных, которыми обмениваются
              компьютерные программы. В целом этот термин обычно относится к
              файлам cookie HTTP, которые веб-сайты используют для хранения
              пользовательских данных локально и на сервере, чтобы сделать
              отдельные функции и веб-приложения, такие как интернет-магазины,
              более удобными для пользователя.
          </p>
          <p>
              8.2 BESTBOOKSHOP использует необходимые файлы cookie, которые
              помогают сделать веб-сайт удобным для использования, обеспечивая
              основные функции, такие как навигация по страницам, использование
              корзины для покупок, аутентификация на сайте для входа в систему,
              доступ к защищенным областям веб-сайта. Без этих файлов cookie вебсайт не может функционировать должным
              образом, и они используются
              исключительно Администратором BESTBOOKSHOP. Соответственно,
              данные, хранящиеся в этих файлах cookie, отправляются только на вебсайт магазина. Некоторые из этих файлов
              cookie автоматически
              удаляются после закрытия браузера (так называемые «сеансовые
              файлы cookie»), некоторые из этих файлов cookie остаются на вашем
              конечном устройстве в течение более длительного периода времени и
              позволяют сохранять настройки страницы (так называемые «постоянные
              файлы cookie»). В последнем случае вы можете найти период хранения
              в обзоре настроек файлов cookie в своем веб-браузере.
          </p>
          <p>
              8.3 Если отдельные файлы cookie, используемые нами, обрабатывают
              персональные данные, то обработка происходит в соответствии со
              статьей 6 (1) (b) GDPR либо для исполнения договора (оплата и доставка
              товара), в соответствии со статьей 6 (1) (a) GDPR, в случае если это
              согласие было дано, или в соответствии со статьей 6 (1) (f) Общего
              регламента по защите данных для защиты наших законных интересов в
              максимально возможной функциональности веб-сайта и удобном и
              эффективном дизайне посещения веб-сайта.
          </p>
          <p>
              8.4 BESTBOOKSHOP не использует статистические или маркетинговые
              (рекламные) файлы cookie, не собирает и не передает личные данные
              пользователей третьим лицам без их ведома.
          </p>
          <p>
              8.5 Тем не менее, наш сайт содержит ссылки на сторонние веб-сайты
              (например, для оплаты товара) с политиками конфиденциальности,
              отличными от BESTBOOKSHOP. Когда вы получаете к ним доступ, вы
              можете решить, принимать их или нет.
          </p>
          <p>
              8.6 Мы не несем ответственности за содержание других веб-сайтов, на
              которые ведут гиперссылки на нашем сайте или которые содержат
              гиперссылки на наш сайт. Гиперссылки, отображаемые на сайте,
              предназначены только для вашего удобства. Вы используете
              гиперссылки на другие веб-сайты и домашние страницы или
              размещенные на них гиперссылки под собственную ответственность. Мы
              не несем никакой ответственности за проверку или оценку других вебсайтов, с которых или на которые ведут
              гиперссылки на нашем сайте.
              Мы не гарантируем доступность других веб-сайтов, а также не
              принимаем на себя никакой ответственности за работу, контент,
              продукты или услуги других веб-сайтов, включая их политику
              конфиденциальности и условия использования. Вам следует
              внимательно ознакомиться с условиями использования и политикой
              конфиденциальности других веб-сайтов, которые вы посещаете.
          </p>
          <p>
              8.7 Вы можете настроить свой браузер таким образом, чтобы быть
              проинформированным о настройке файлов cookie и могли принимать
              индивидуальное решение, принимать их или исключать принятие
              файлов cookie для определенных случаев или в целом. Обратите
              внимание, что если файлы cookie не принимаются, функциональность
              нашего веб-сайта может быть ограничена.
          </p>

          <h3>§9 ПРАВА СУБЪЕКТА ДАННЫХ </h3>
          <p>
              Ниже приводится сводка прав, которыми вы обладаете в соответствии с
              Европейским законодательством о защите персональных данных. Реализация
              этих прав осуществляется бесплатно посредством отправки электронного
              письма по адресу:{" "}
              <a href="mailto:support@bestbookshop.eu">support@bestbookshop.eu</a>.
          </p>
          <p>
              Имейте в виду, что BESTBOOKSHOP всегда проводит оценку запросов на
              реализацию права на предмет их допустимости.{" "}
          </p>
          <p>
              Все приведенные ниже права не являются абсолютными, и для них могут
              действовать исключения.
          </p>
          <p>
              В дополнение к приведенным ниже правам вы всегда можете зарегистрировать
              жалобу в надзорном органе относительно обработки ваших персональных
              данных компанией Premier Logistic OÜ.
          </p>
          <p>
              9.1 Право доступа. Вы имеете право по запросу получить копию своих
              персональных данных, обрабатываемых BESTBOOKSHOP, а также дополнительные
              сведения об обработке своих персональных данных компанией Premier
              Logistic OÜ, в соответствии со статьей 15 GDPR.
          </p>
          <p>
              9.2 Право на внесение исправлений. Вы имеете право исправлять и/или
              дополнять свои персональные данные, если они оказываются неточными и/или
              неполными, в соответствии со статьей 16 GDPR.
          </p>
          <p>
              9.3 Право на удаление. Вы имеете право потребовать от BESTBOOKSHOP
              удалить ваши персональные данные в соответствии со статьей 17 GDPR без
              необоснованной задержки в следующих ситуациях: ─ персональные данные
              более не связаны с целями, для которых они собирались или обрабатывались
              другим образом; ─ вы отзываете свое согласие на обработку, и для
              обработки нет никаких других законных оснований; ─ вы направляете
              обоснованное возражение в отношении обработки ваших персональных данных;
              ─ обработка персональных данных является неправомерной; ─ персональные
              данные должны быть удалены в соответствии с законом.
          </p>
          <p>
              9.4 Право на ограничение обработки. Вы имеете право потребовать
              ограничить объем обработки своих персональных данных в соответствии со
              статьей 18 GDPR в следующих ситуациях: ─ идет оценка точности
              персональных данных; ─ обработка является неправомерной либо более не
              требуется в заявленных ранее целях, однако вы не хотите удалять
              персональные данные и требуете вместо этого ограничить обработку; ─
              BESTBOOKSHOP более не нуждается в персональных данных, но вам нужны
              персональные данные для подачи судебных исков или для защиты по судебным
              искам; ─ вы подали возражение против обработки своих персональных
              данных, и в настоящее время идет проверка этого возражения.
          </p>
          <p>
              9.5 Право на перемещение данных в соответствии со статьей 20 GDPR. В
              некоторых обстоятельствах у вас есть право получить свои персональные
              данные, предоставленные BESTBOOKSHOP, с целью их передачи другому
              поставщику услуг, если обработка персональных данных обусловлена вашим
              согласием или исполнением договора.
          </p>
          <p>
              9.6 Универсальное право на возражение. У вас есть универсальное право в
              любое время опротестовать обработку своих персональных данных, которая
              осуществляется на основании законных интересов BESTBOOKSHOP. Если вы
              заявляете свой протест, мы должны будем продемонстрировать наличие
              убедительных законных оснований для такой обработки, или персональные
              данные требуются нам для подачи судебных исков или защиты от судебных
              исков.
          </p>
          <p>
              9.7 Право отозвать предоставленное согласие на обработку персональных
              данных в соответствии со статьей 7 параграфом 3 Общего регламента по
              защите данных.
          </p>
          <p>
              9.8 Право на подачу жалобы в соответствии со статьей 77 Общего
              регламента по защите данных.
          </p>

          <h3>§10 СРОК ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
          <p>
              10.1 Продолжительность хранения персональных данных зависит от
              соответствующей правовой основы, цели обработки и, если применимо, также
              от установленного законом срока хранения (например, коммерческого и
              налогового периодов хранения).
          </p>
          <p>
              10.2 При обработке персональных данных на основании явного согласия в
              соответствии со статьей 6, абзацем 1, подпунктом a Общего регламента по
              защите данных, эти данные хранятся до тех пор, пока заинтересованное
              лицо не отзовет свое согласие.
          </p>
          <p>
              10.3 Если существуют установленные законом сроки хранения данных,
              которые обрабатываются в рамках юридических или аналогичных обязательств
              на основании статьи 6 (1) (b) Общего регламента по защите данных, эти
              данные будут регулярно удаляться по истечении срока хранения, при
              условии, что они больше не требуется для выполнения или инициирования
              договора, и/или у нас нет законного интереса к дальнейшему хранению.
          </p>
          <p>
              10.4 Когда персональные данные обрабатываются на основании статьи 6 (1)
              (f) GDPR, эти данные хранятся до тех пор, пока субъект данных не
              воспользуется своим правом на возражение в соответствии со статьей 21
              (1) GDPR.
          </p>
          <p>
              10.5 Сохраненные персональные данные будут удалены, когда они больше не
              нужны для целей, для которых они были собраны или иным образом
              обработаны.
          </p>

          <h3>§11 КОНТАКТНАЯ ИНФОРМАЦИЯ</h3>
          <p>Вы можете связаться с нами следующими способами:</p>
          <p>Почтовый адрес: Ostrowski Str. 3 04357 Leipzig Germany</p>
          <p>
              Контактный телефон: <a href="tel:+491723462609">+491723462609</a>
          </p>
          <p>
              Эл. почта:{" "}
              <a href="mailto:support@bestbookshop.eu">support@bestbookshop.eu</a>
          </p>
      </div>
  );
};

export default Confidentiality;
