import { Routes, Route, BrowserRouter, Link } from "react-router-dom";
import logo from "../../assets/logofooter.svg";
import "../../App.css";
import "./footer.css";

const Footer = () => {
  const onSetPosition = () => {
    window.scrollTo(0, 500);
    localStorage.removeItem("scrollY");
    localStorage.removeItem("pageN");
    localStorage.removeItem("scrollYBooks");
    localStorage.removeItem("limitbook");
  };

  return (
    <div className="footer">
      <div className="footer-flex container">
        <div className="footer-item footer-logo">
          <Link
            onClick={() => {
              localStorage.removeItem("cat");
              localStorage.removeItem("s");
              localStorage.removeItem("scrollY");
              localStorage.removeItem("pageN");
              localStorage.removeItem("scrollStoryY");
              localStorage.removeItem("pageNStory");
              localStorage.removeItem("scrollYBooks");
              localStorage.removeItem("limitbook");
              window.scrollTo(0, 10);
            }}
            to={"/"}
          >
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="inf-block footer-item">
          <h3>Информация</h3>
          <p className="pointer">
            <Link onClick={onSetPosition} to="/terms">
              Пользовательское соглашение
            </Link>
          </p>
          <p className="pointer">
            <Link onClick={onSetPosition} to="/notice">
              Правовое уведомление
            </Link>
          </p>
          <p className="pointer">
            <Link onClick={onSetPosition} to="/public-offer">
              Публичная оферта
            </Link>
          </p>
          <p className="pointer">
            <Link onClick={onSetPosition} to="/confidentiality">
              Уведомление о конфиденциальности и защите персональных данных
            </Link>
          </p>
          <p className="pointer">
            <Link onClick={onSetPosition} to="/conditions">
              Условия возврата товара
            </Link>
          </p>
        </div>
        <div className="footer-item">
          <h3>Аккаунт</h3>
          <p className="pointer">
            <Link onClick={onSetPosition} to="/user">
              Моя учетная запись
            </Link>
          </p>
          <p className="pointer">
            <Link onClick={onSetPosition} to="/user/cart">
              Моя корзина
            </Link>
          </p>
          <p className="pointer">
            <Link onClick={onSetPosition} to="/story-order">
              Мои заказы
            </Link>
          </p>
        </div>
        <div className="footer-item">
          <h3>Контакты</h3>
          <p>Premier Logistic OÜ</p>
          <p>Tõru 15, Tallinn, 12011, Estonia</p>
          <p className="pointer">
            <a href="tel:+491723462609">+491723462609</a>
          </p>
          <p className="pointer">
            <a href="mailto:support@bestbookshop.eu">support@bestbookshop.eu</a>
          </p>
          <p></p>
          <p></p>
        </div>
      </div>
      <div className="footer-c">
        Copyright © Premier Logistic OÜ, 2023. Все права защищены
      </div>
    </div>
  );
};

export default Footer;
