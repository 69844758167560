import { useEffect, useState } from "react";
import "./user-store-story.css";
import { BASE_URL } from "../../Enter/Auth/auth";
import { send_request } from "../../Enter/Auth/setAuthToken";
import Accordion from "../../../components/accordion/accordion";
import PaginationPanel from "../../../components/paginationPanel/paginationPanel";

const UserStoreStory = () => {
  let [data, setData] = useState();
  let [treck, setTreck] = useState();
  let [isModal, setModal] = useState(false);
  let [isLimit, setIsLimit] = useState(true);
  let [limit, setLimit] = useState(5);
  let storeId = window.location.pathname.split("/").pop();

  const getOrders = () => {
    send_request
      .get(BASE_URL + "user/stores/" + storeId + "/orders", {
        params: { limit: limit, id: 0 },
      })
      .then((res) => {
        if (res.data.orders == null) {
          // window.location.assign("/not-found");
        } else if (res.data.orders.length < limit || limit <= 0) {
          setIsLimit(false);
        } else {
          setIsLimit(true);
        }
        setData(res.data.orders);
      })
      .catch((err) => {
        if (err.response.status == 400) {
          window.location.assign("/not-found");
        }
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };
  const nextPage = () => {
    if (isLimit) {
      setLimit(limit + 5);
    }
  };
  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, []);
  useEffect(() => {
    getOrders();
  }, [limit]);

  return (
    <div className="cart container">
      <h2 className="user-store handler-order">Заказы</h2>

      {data ? (
        <div className="user-store">
          {data &&
            data.map((i, ind) => (
              <div key={ind}>
                <Accordion
                  active={false}
                  title={
                    <>
                      <div className="order-in-story">
                        <h3>ID {i.id}</h3>
                        <span className="sup-name">
                          <b>
                            {i.recipient.lastname} {i.recipient.firstname[0]}.{" "}
                          </b>
                        </span>
                        <span className="sup-status">
                          {" "}
                          <b>{i.totalPrice.toFixed(2)} €</b>{" "}
                          <span
                            className={
                              i.status == "Ожидает оплаты"
                                ? "yellow-text"
                                : i.status == "Необработан"
                                ? "red-text"
                                : "green-text"
                            }
                          >
                            {" "}
                            {i.status}
                          </span>
                        </span>
                      </div>
                      <p>{i.createdAt}</p>
                    </>
                  }
                  content={
                    <>
                      <table className="order-table">
                        <tr>
                          <th>Фото</th>
                          <th>Название книги</th>
                          <th>Кол-во</th>
                          <th>Цена за единицу</th>
                          <th>Цена общая</th>
                        </tr>
                        {i.books.map((item, index) => (
                          <tr key={index}>
                            <td className="first-column">
                              <img
                                className="img-order-story"
                                src={item.image}
                                alt={item.title}
                              />
                            </td>
                            <td>
                              <h4 className="order-table__book-title">
                                {item.title}
                              </h4>
                            </td>
                            <td>{item.quantity}</td>
                            <td>{item.price.toFixed(2)} €</td>
                            <td>{item.totalPrice.toFixed(2)} €</td>
                          </tr>
                        ))}
                      </table>
                      <Accordion
                        active={false}
                        title={<b>Покупатель</b>}
                        content={
                          <div>
                            <p>
                              <b>Имя:</b> {i.recipient.firstname}
                            </p>
                            <p>
                              <b>Фамилия:</b> {i.recipient.lastname}
                            </p>
                            <p>
                              <b>Email:</b> {i.recipient.email}
                            </p>
                            <p>
                              <b>Адрес:</b> {i.recipient.address}
                            </p>
                            <p>
                              <b>Номер телефона:</b> {i.recipient.phoneNumber}
                            </p>
                            {i.recipient.comment && (
                              <p>
                                <b>Комментарий:</b> {i.recipient.comment}
                              </p>
                            )}
                          </div>
                        }
                      />
                      <table className="tbl-price">
                        <tr>
                          <th>Стоимость доставки</th>
                          <th>Стоимость книг</th>
                          <th>Общая стоимость</th>
                        </tr>
                        <tr>
                          <td>{i.shipmentPrice.toFixed(2)} €</td>
                          <td>{i.totalBooksPrice.toFixed(2)} €</td>
                          <td>{i.totalPrice.toFixed(2)} €</td>
                        </tr>
                      </table>
                      <div>
                        {i.shipmentDetails ? (
                          <div>
                            <p>Отправленно: {i.shipmentDetails.sentAt}</p>
                            <p>
                              Трек номер: {i.shipmentDetails.trackingNumber}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p>Заказ не закрыт</p>
                          </div>
                        )}
                        {i.status == "Необработан" && (
                          <button
                            className="btn"
                            onClick={() => {
                              localStorage.setItem("track", i.id);
                              setModal(true);
                            }}
                          >
                            Ввести трек номер
                          </button>
                        )}
                      </div>
                      {isModal && (
                        <div className="modal1" onClick={() => setModal(false)}>
                          <div
                            className="modal1-dialog"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="modal1-header">
                              <h3 className="modal1-title">
                                Введите трек номер
                              </h3>
                            </div>
                            <div className="modal1-body">
                              <div className="modal1-content">
                                <input
                                  name="treck"
                                  onChange={(e) => setTreck(e.target.value)}
                                />
                                <button
                                  onClick={() => {
                                    send_request
                                      .patch(
                                        BASE_URL +
                                          "user/stores/" +
                                          storeId +
                                          "/orders/" +
                                          localStorage.getItem("track"),
                                        { trackingNumber: treck }
                                      )
                                      .then((res) => {
                                        localStorage.removeItem("track");
                                        setModal(false);
                                        getOrders();
                                      })
                                      .catch((err) => {
                                        localStorage.removeItem("track");
                                        if (err.response.status === 500) {
                                          window.location.assign("/error");
                                        }
                                      });
                                  }}
                                  className="btn"
                                >
                                  Ввести
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  }
                />
              </div>
            ))}
          <div>{isLimit && <PaginationPanel nextPage={nextPage} />}</div>
        </div>
      ) : (
        <div>У вас нет заказов</div>
      )}
    </div>
  );
};

export default UserStoreStory;
