import "./notfound.css";
import Img404 from "../../assets/404page.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    localStorage.removeItem("sort");
    localStorage.removeItem("s");
    localStorage.removeItem("cat");
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, []);

  return (
    <div className="not-found container">
      <img src={Img404} alt="not found" />
      <p>
        Возможно, она была перемещена, или вы просто неверно указали адрес
        страницы.
      </p>
      <Link to={"/"}>Перейти на главную</Link>
    </div>
  );
};

export default NotFound;
