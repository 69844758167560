import { useEffect, useState } from "react";
import "../../App.css";
import "./cookie.css";
import cookie from "../../assets/cookie.png";
import { Link } from "react-router-dom";

const Cookie = ({ changeCookie }) => {
  useEffect(() => {}, []);
  return (
    <div className="cookie">
      <div className="cookie-desc">
        <img src={cookie} alt="cookie" />
        <div>
          <p>
            Мы используем файлы cookie, чтобы обеспечить необходимую
            функциональность сайта и улучшить ваш опыт. Используя наш веб-сайт,
            вы соглашаетесь с{" "}
            <Link to="/confidentiality">
              <u>
                {" "}
                Уведомлением о конфиденциальности и защите персональных данных
              </u>
            </Link>
          </p>
        </div>
      </div>
      <div className="btn-ok">
        <button
          className="btn"
          onClick={() => {
            localStorage.setItem("cookie", true);
            changeCookie();
          }}
        >
          Принимаю
        </button>
      </div>
    </div>
  );
};

export default Cookie;
