import axios from "axios";
import { useEffect, useState } from "react";
import "../../App.css";
import "./user-card.css";
import { send_request } from "../../pages/Enter/Auth/setAuthToken";
import { BASE_URL } from "../../pages/Enter/Auth/auth";
import trash from "../../assets/trash.png";
import { Link } from "react-router-dom";
import empty from "../../assets/пустая корзина покупателя.png";
import cleanCartImg from "../../assets/корзина зеленая.png";
import { useDispatch } from "react-redux";
import { getInfoCart } from "../../store/Actions/Action";

const UserCard = () => {
  let [cart, setCart] = useState();
  let [quantity, setQuantity] = useState(1);
  let [countries, setCountries] = useState();
  let [shipPrice, setShipPrice] = useState(0);
  let [priceProd, setPriceProd] = useState(0);
  let [selectCountry, setSelectCountry] = useState(0);
  let arrBooks = JSON.parse(localStorage.getItem("cart"));
  const dispatch = useDispatch();

  useEffect(() => {
    getCart();
    axios
      .get(BASE_URL + "countries")
      .then((res) => {
        setCountries(res.data.countries);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  }, [selectCountry]);

  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();

    if (localStorage.getItem("access")) {
      send_request
        .get(BASE_URL + "user")
        .then((res) => {
          setSelectCountry(res.data.user.countryID);
          res.data.user.countryID &&
            localStorage.setItem("country", res.data.user.countryID);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.getItem("country") &&
        setSelectCountry(localStorage.getItem("country"));
    }
  }, []);

  const getCart = () => {
    if (localStorage.getItem("access")) {
      send_request
        .get(BASE_URL + "user/cart", {
          params: {
            country: localStorage.getItem("country")
              ? localStorage.getItem("country")
              : selectCountry,
          },
        })
        .then((res) => {
          setCart(res.data.cart);
          res.data.cart.stores.map((i) => {
            if (i.shippingPrice !== null) {
              if (isNaN(shipPrice)) {
                setShipPrice(0);
              }
              setShipPrice(shipPrice + i.shippingPrice);
            }
            i.books.map((item) => {
              setPriceProd(priceProd + item.price * quantity);
            });
          });
        })
        .catch((err) => {
          if (err.response.status === 500) {
            window.location.assign("/error");
          }
        });
    } else {
      axios
        .post(
          BASE_URL + "cart",
          { books: arrBooks },
          {
            params: {
              country: localStorage.getItem("country")
                ? localStorage.getItem("country")
                : selectCountry,
            },
          }
        )
        .then((res) => {
          setCart(res.data.cart);
          res.data.cart.stores.map((i) => {
            if (i.shippingPrice !== null) {
              if (isNaN(shipPrice)) {
                setShipPrice(0);
              }
              setShipPrice(shipPrice + i.shippingPrice);
            }
            i.books.map((item) => {
              setPriceProd(priceProd + item.price * quantity);
            });
          });
        })
        .catch((err) => {
          if (err.response.status === 500) {
            window.location.assign("/error");
          }
        });
    }
  };
  return (
    <div className="cart container">
      <div className="handler-cart">
        <h2>Корзина</h2>
        {cart && cart.stores && (
          <img
            src={cleanCartImg}
            className="btn-delete-cart"
            onClick={() => {
              if (localStorage.getItem("access")) {
                send_request
                  .delete(BASE_URL + "user/cart/clear")
                  .then(() => {
                    dispatch(getInfoCart());
                    getCart();
                    window.location.reload();
                  })
                  .catch((err) => {
                    if (err.response.status === 500) {
                      window.location.assign("/error");
                    }
                  });
              } else {
                localStorage.removeItem("cart");
                getCart();
                dispatch(getInfoCart());
                window.location.reload();
              }
            }}
            alt="очистить корзину"
          />
        )}
      </div>
      <div className="all-cart">
        {cart && cart.stores ? (
          <div>
            {countries && (
              <>
                <p>Выберите страну доставки </p>
                <select
                  onChange={(e) => {
                    setSelectCountry(e.target.value);
                    localStorage.setItem("country", e.target.value);
                  }}
                  className="select-country"
                  value={
                    localStorage.getItem("country")
                      ? localStorage.getItem("country")
                      : ""
                  }
                  defaultValue={
                    localStorage.getItem("country")
                      ? localStorage.getItem("country")
                      : ""
                  }
                >
                  <option value="">Страна</option>
                  {countries.map((i, ind) => (
                    <option key={ind} value={i.id}>
                      {i.country}
                    </option>
                  ))}
                </select>
              </>
            )}
            {cart.stores.map((i, index) => (
              <div key={index}>
                <div className="brand">
                  {i.brand}{" "}
                  {i.shippingWeight > 0 && (
                    <span> ({i.shippingWeight} гр.)</span>
                  )}
                </div>
                <table>
                  {i.books.map((item, ind) => (
                    <tr key={ind}>
                      <td className="img-prod">
                        {item.message ? (
                          <img src={item.image} alt={item.title} />
                        ) : (
                          <Link to={"/books/" + item.id}>
                            <img src={item.image} alt={item.title} />
                          </Link>
                        )}
                      </td>
                      <td className="description-prod">
                        <h3>{item.title}</h3>
                        <p>
                          <b>ID:</b> {item.id}
                        </p>
                        <p>
                          <b>Вес:</b> {item.weight} гр.
                        </p>
                        <p>
                          <span className="price-prod">
                            {item.price.toFixed(2)} €
                          </span>{" "}
                          х {item.quantity}
                        </p>
                        {item.message && <p className="err">{item.message}</p>}
                      </td>
                      <td className="quantity-prod">
                        <div className="book-quantity">
                          {item.quantity > 1 && (
                            <div
                              className="pointer"
                              onClick={() => {
                                if (localStorage.getItem("access")) {
                                  item.quantity > 1 &&
                                    send_request
                                      .patch(
                                        BASE_URL + "user/cart/" + item.id,
                                        { quantity: item.quantity - 1 }
                                      )
                                      .then(() => {
                                        dispatch(getInfoCart());
                                        getCart();
                                      })
                                      .catch((err) => {
                                        if (err.response.status === 500) {
                                          window.location.assign("/error");
                                        }
                                      });
                                } else {
                                  if (item.quantity > 1) {
                                    let indBook = arrBooks.indexOf(
                                      arrBooks.find(
                                        (book) => book.id == item.id
                                      )
                                    );
                                    arrBooks[indBook].quantity =
                                      arrBooks[indBook].quantity - 1;
                                    localStorage.setItem(
                                      "cart",
                                      JSON.stringify(arrBooks)
                                    );
                                    dispatch(getInfoCart());
                                    getCart();
                                  }
                                }
                              }}
                            >
                              -
                            </div>
                          )}
                          <div>{item.quantity}</div>
                          {item.quantity < item.availableQuantity && (
                            <div
                              className="pointer"
                              onClick={() => {
                                if (localStorage.getItem("access")) {
                                  item.quantity < item.availableQuantity &&
                                    send_request
                                      .patch(
                                        BASE_URL + "user/cart/" + item.id,
                                        { quantity: item.quantity + 1 }
                                      )
                                      .then(() => {
                                        dispatch(getInfoCart());
                                        getCart();
                                      })
                                      .catch((err) => {
                                        if (err.response.status === 500) {
                                          window.location.assign("/error");
                                        }
                                      });
                                } else {
                                  if (item.quantity < item.availableQuantity) {
                                    let indBook = arrBooks.indexOf(
                                      arrBooks.find(
                                        (book) => book.id == item.id
                                      )
                                    );
                                    arrBooks[indBook].quantity += 1;
                                    localStorage.setItem(
                                      "cart",
                                      JSON.stringify(arrBooks)
                                    );
                                    dispatch(getInfoCart());
                                    getCart();
                                  }
                                }
                              }}
                            >
                              +
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="delete-prod">
                        <img
                          src={trash}
                          className="pointer"
                          alt="delete"
                          onClick={() => {
                            if (localStorage.getItem("access")) {
                              send_request
                                .delete(BASE_URL + "user/cart/" + item.id)
                                .then((res) => {
                                  dispatch(getInfoCart());
                                  getCart();
                                })
                                .catch((err) => {
                                  if (err.response.status === 500) {
                                    window.location.assign("/error");
                                  }
                                });
                            } else {
                              arrBooks.splice(
                                arrBooks.indexOf(
                                  arrBooks.find((book) => book.id == item.id)
                                ),
                                1
                              );
                              localStorage.setItem(
                                "cart",
                                JSON.stringify(arrBooks)
                              );
                              dispatch(getInfoCart());
                              getCart();
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            ))}
            {cart.totalBooksPrice !== 0 ? (
              <>
                <div className="all-panel">
                  <div>Всего товаров:</div>
                  <div>{cart.totalBooksPrice.toFixed(2)} €</div>
                </div>
                <div className="all-panel">
                  <div>Доставка:</div>
                  <div>
                    {localStorage.getItem("country") == null ||
                    cart.totalShippingPrice == 0
                      ? "Выберите страну доставки"
                      : cart.totalShippingPrice.toFixed(2) + " €"}
                  </div>
                </div>
                <div className="all-panel summery">
                  <h3>Цена:</h3>
                  <div>
                    {localStorage.getItem("country") == null
                      ? "Выберите страну доставки"
                      : (
                          cart.totalShippingPrice + cart.totalBooksPrice
                        ).toFixed(2) + " €"}
                  </div>
                </div>
              </>
            ) : (
              <div className="prod-blocked">
                Все товары заблокированы или проданы
              </div>
            )}

            <div className="btn-go-check">
              <Link className="btn" to={"/"}>
                Продолжить покупки
              </Link>
              {cart.totalShippingPrice !== 0 &&
                localStorage.getItem("country") && (
                  <Link className="btn" to={"/user/order"}>
                    Перейти к оформлению
                  </Link>
                )}
            </div>
          </div>
        ) : (
          cart !== undefined && (
            <div className="empty">
              <br />
              <b>Здесь пока ничего нет</b>
              <div className="empty-block">
                <img src={empty} alt="Корзина пуста" />
                <Link className="btn" to={"/"}>
                  Перейти к покупкам{" "}
                </Link>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default UserCard;
