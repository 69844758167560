import axios from "axios";
import { useEffect, useState } from "react";
import "./user-store-book.css";
import { BASE_URL } from "../../Enter/Auth/auth";
import { send_request } from "../../Enter/Auth/setAuthToken";
import { Link } from "react-router-dom";
import trash from "../../../assets/trash.png";
import lock from "../../../assets/замок закрытый.png";
import lock2 from "../../../assets/замок открытый.png";
import lock3 from "../../../assets/замок закрытый с  галочкой.png";
import lock4 from "../../../assets/замок открытый с галочкой.png";
import PaginationPanel from "../../../components/paginationPanel/paginationPanel";
import addBook from "../../../assets/добавление книг.png";
import CustomSelect from "../../../components/select/select";

const UserStoreBooks = () => {
  let [books, setBooks] = useState();
  let [isLimit, setIsLimit] = useState(true);
  let [isBooks, setIsBooks] = useState(false);
  let [limit, setLimit] = useState(
    localStorage.getItem("limitbook") ? localStorage.getItem("limitbook") : 10
  );

  const [checked, setChecked] = useState([]);
  let storeId = window.location.pathname.split("/").pop();
  let [sort, setSort] = useState(
    localStorage.getItem("sortbook") && localStorage.getItem("sortbook")
  );
  let [scrollPosition, setScrollPosition] = useState(
    localStorage.getItem("scrollYBooks")
      ? localStorage.getItem("scrollYBooks")
      : 370
  );

  useEffect(() => {
    setLimit(
      localStorage.getItem("limitbook") ? localStorage.getItem("limitbook") : 10
    );
    window.scrollTo(0, scrollPosition);
  }, [books]);

  useEffect(() => {
    getBooks();
  }, [sort, limit]);

  const getBooks = () => {
    send_request
      .get(BASE_URL + "user/stores/" + storeId + "/books", {
        params: { limit: limit, id: 0, sort: sort },
      })
      .then((res) => {
        if (res.data.books == null) {
          // window.location.assign("/not-found");
        } else if (res.data.books.length < limit || limit <= 0) {
          setIsLimit(false);
        } else {
          setIsLimit(true);
        }
        setBooks(
          res.data.books && res.data.books.length > 1 ? res.data.books : []
        );
      })
      .catch((err) => {
        if (err.response.status == 400) {
          window.location.assign("/not-found");
        }
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };

  const handleCheck = (event) => {
    localStorage.setItem("scrollYBooks", window.scrollY);

    var updatedList = [...checked];
    if (+event.target.checked) {
      updatedList = [...checked, +event.target.value];
    } else {
      updatedList.splice(checked.indexOf(+event.target.value), 1);
    }
    setChecked(updatedList);
  };
  const nextPage = () => {
    if (isLimit) {
      localStorage.setItem("scrollYBooks", window.scrollY);
      setScrollPosition(window.scrollY);
      localStorage.setItem("limitbook", Number(limit) + 15);
      setLimit(Number(limit) + 15);
    }
  };
  const [options, setOptions] = useState([
    {
      label: "Сначала старые",
      value: "id:asc",
    },
    {
      label: "Сначала новые",
      value: "id:desc",
    },
    {
      label: "Сначала заблокированные",
      value: "isBlocked:desc",
    },
    {
      label: "Сначала разблокированные",
      value: "isBlocked:asc",
    },
  ]);

  const handleChangeSelect = (e) => {
    localStorage.setItem("sortbook", e.value);
    localStorage.removeItem("scrollYBooks");
    localStorage.removeItem("limitbook");
    setSort(e.value);
  };
  return (
    <div className="user-store container">
      {books && books.length > 1 ? (
        <div className="block-books-store">
          <div className="btns-books">
            <button
              className="btn"
              onClick={() => {
                send_request
                  .post(BASE_URL + "user/stores/" + storeId + "/books/blockall")
                  .then((res) => {
                    getBooks();
                  })
                  .catch((err) => {
                    console.log(err);
                    if (err.response.status === 500) {
                      window.location.assign("/error");
                    }
                  });
              }}
            >
              <img src={lock} alt="lock" />
              <div className="block-unblock-text">
                Заблокировать
                <br />
                все книги
              </div>
            </button>
            <button
              onClick={() => {
                send_request
                  .post(
                    BASE_URL + "user/stores/" + storeId + "/books/unblockall"
                  )
                  .then((res) => {
                    getBooks();
                  })
                  .catch((err) => {
                    console.log(err);
                    if (err.response.status === 500) {
                      window.location.assign("/error");
                    }
                  });
              }}
              className="btn"
            >
              <img src={lock2} alt="lock" />
              <div className="block-unblock-text">
                Разблокировать
                <br />
                все книги
              </div>
            </button>
            <button
              onClick={() => {
                if (checked.length > 0) {
                  send_request
                    .post(
                      BASE_URL + "user/stores/" + storeId + "/books/block",
                      {
                        booksIDs: checked,
                      }
                    )
                    .then((res) => {
                      getBooks();
                    })
                    .catch((err) => {
                      console.log(err);
                      if (err.response.status === 500) {
                        window.location.assign("/error");
                      }
                    });
                }
              }}
              className="btn"
            >
              <img src={lock3} alt="lock" />
              <div className="block-unblock-text">
                Заблокировать
                <br />
                выбранные
              </div>
            </button>
            <button
              onClick={() => {
                if (checked.length > 0) {
                  send_request
                    .post(
                      BASE_URL + "user/stores/" + storeId + "/books/unblock",
                      { booksIDs: checked }
                    )
                    .then((res) => {
                      getBooks();
                    })
                    .catch((err) => {
                      console.log(err);
                      if (err.response.status === 500) {
                        window.location.assign("/error");
                      }
                    });
                }
              }}
              className="btn"
            >
              <img src={lock4} alt="lock" />
              <div className="block-unblock-text">
                Разблокировать
                <br />
                выбранные
              </div>
            </button>
          </div>
          <div>
            <div className="block-add-book">
              <CustomSelect
                options={options}
                placeHolder="Сортировать по"
                onChange={(e) => handleChangeSelect(e)}
              />
              {/* <select
    className="sort sort-items"
    onChange={(e) => {
      localStorage.setItem("sortbook", e.target.value);
      setSort(e.target.value);
    }}
  >
    <option value="">Сортировать по</option>
    <option value="id:asc">Сначала старые</option>
    <option value="id:desc">Сначала новые</option>
    <option value="isBlocked:desc">Сначала заблокированные</option>
    <option value="isBlocked:asc">Сначала разблокированные</option>
  </select> */}
              <Link
                className="sort add-book"
                to={"new-book"}
                onClick={() => {
                  localStorage.removeItem("scrollYBooks");
                  localStorage.removeItem("limitbook");
                }}
              >
                Добавить книгу <img src={addBook} alt="+" />
              </Link>
            </div>
            <div className="mobile-table-book">
              <h3>Книги</h3>
              <div>
                {books &&
                  books.map((i, ind) => (
                    <div key={ind} className="mobile-item-book">
                      <div className="mobile-inp-check">
                        <div className="mobile-id-block">
                          <input
                            checked={checked.includes(i.id)}
                            className="inp-check"
                            onChange={handleCheck}
                            value={+i.id}
                            type="checkbox"
                          />
                          <b>{i.id}</b>
                        </div>
                        <img
                          src={trash}
                          onClick={() => {
                            document.getElementsByClassName("inp-check")[
                              ind
                            ].checked = false;
                            send_request
                              .delete(
                                BASE_URL +
                                  "user/stores/" +
                                  storeId +
                                  "/books/" +
                                  i.id
                              )
                              .then((res) => {
                                document.getElementsByClassName("inp-check")[
                                  ind
                                ].checked = false;
                                let x = checked.indexOf(i.id);
                                checked.splice(x, 1);
                                setChecked(checked);
                                getBooks();
                              })
                              .catch((err) => {
                                console.log(err);
                                if (err.response.status === 500) {
                                  window.location.assign("/error");
                                }
                              });
                          }}
                          className="trash-btn"
                          alt="delete"
                        />
                      </div>
                      <div className="mobile-book-item">
                        <div>
                          <Link
                            to={
                              "/store-books/" + storeId + "/edit-book/" + i.id
                            }
                            onClick={() => {
                              localStorage.setItem(
                                "scrollYBooks",
                                window.scrollY
                              );
                            }}
                          >
                            <img
                              className="img-book"
                              src={i.image}
                              alt={i.title}
                            />
                          </Link>
                        </div>
                        <div className="title-book">
                          <h2>{i.title}</h2>
                          <b className="nowrap">{i.price.toFixed(2)} €</b>
                        </div>
                      </div>
                      <div>
                        <b>Статус: </b>
                        {i.isBlocked ? "заблокировано" : "разблокировано"}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <table className="table-books-shop">
              <tr>
                <th></th>
                <th>ID</th>
                <th>Книга</th>
                <th>Цена</th>
                <th className="status-block">Статус</th>
              </tr>
              {books &&
                books.map((i, ind) => (
                  <tr className="book-store" key={ind}>
                    <td>
                      <input
                        checked={checked.includes(i.id)}
                        className="inp-check"
                        onChange={handleCheck}
                        value={+i.id}
                        type="checkbox"
                      />
                    </td>
                    <td>
                      <div>
                        <b>{i.id}</b>
                      </div>
                    </td>
                    <td className="td-name">
                      <div>
                        <Link
                          to={"/store-books/" + storeId + "/edit-book/" + i.id}
                          onClick={() => {
                            localStorage.setItem(
                              "scrollYBooks",
                              window.scrollY
                            );
                          }}
                        >
                          <img
                            className="img-book"
                            src={i.image}
                            alt={i.title}
                          />
                        </Link>
                      </div>
                      <div className="title-book">
                        <h2>{i.title}</h2>
                      </div>
                    </td>
                    <td className="price-td">
                      <div className="title-book">
                        <b className="nowrap">{i.price.toFixed(2)} €</b>
                      </div>
                    </td>
                    <td className="status-block">
                      <div>
                        {i.isBlocked ? "заблокировано" : "разблокировано"}
                      </div>
                    </td>
                    <td
                      onClick={() => {
                        document.getElementsByClassName("inp-check")[
                          ind
                        ].checked = false;
                        send_request
                          .delete(
                            BASE_URL +
                              "user/stores/" +
                              storeId +
                              "/books/" +
                              i.id
                          )
                          .then((res) => {
                            document.getElementsByClassName("inp-check")[
                              ind
                            ].checked = false;
                            let x = checked.indexOf(i.id);
                            checked.splice(x, 1);
                            setChecked(checked);
                            getBooks();
                          })
                          .catch((err) => {
                            console.log(err);
                            if (err.response.status === 500) {
                              window.location.assign("/error");
                            }
                          });
                      }}
                      className="trash-btn"
                    >
                      <img src={trash} alt="delete" />
                    </td>
                  </tr>
                ))}
            </table>
            <div className="pag-comp">
              {isLimit && <PaginationPanel nextPage={nextPage} />}
            </div>
          </div>
        </div>
      ) : books && books.length < 1 ? (
        <div className="block-empty-books-store">
          <h2>У вас еще нет книг</h2>
          <Link
            className="sort add-book"
            to={"new-book"}
            onClick={() => {
              localStorage.removeItem("scrollYBooks");
              localStorage.removeItem("limitbook");
            }}
          >
            Добавить книгу <img src={addBook} alt="+" />
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserStoreBooks;
