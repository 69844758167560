import axios from "axios";
import { useEffect, useState } from "react";
import "./signin.css";
import { BASE_URL } from "../Auth/auth";
import { ClientJS } from "clientjs";
import visible from "../../../assets/видимый.png";
import notvisible from "../../../assets/скрытый.png";

const SignIn = () => {
  const client = new ClientJS();
  let [isHide, setHide] = useState(true);

  const fingerprint = client.getFingerprint();
  const [entry, setEntry] = useState(() => {
    return {
      email: "",
      password: "",
      useragent: window.navigator.userAgent,
      fingerprint: fingerprint.toString(),
    };
  });
  const [entryErr, setEntryErr] = useState(() => {
    return {
      email: "",
      password: "",
    };
  });
  const changeInputEntry = (event) => {
    event.persist();
    setEntry((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const validate = (values) => {
    let errors = {};
    if (
      values.password.length < 8 ||
      /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/.test(values.email) === false
    ) {
      errors.password = "Вы ввели неверный логин или пароль!";
    }
    return errors;
  };

  const setSignIn = () => {
    axios
      .post(BASE_URL + "signin", entry, { withCredentials: true })
      .then((res) => {
        localStorage.setItem("access", res.data.accessToken);
        localStorage.removeItem("country");
        localStorage.removeItem("cart");
        window.location.assign("/user");
      })
      .catch((err) => {
        if (err.response.data.err == "invalid password") {
          setEntryErr({ password: "Неверный пароль" });
        }
        if (err.response.data.err == "user not exist") {
          setEntryErr({ password: "Пользователя с таким email не существует" });
        }
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };

  useEffect(() => {
    entry && setEntryErr(validate(entry));
  }, [entry]);
  return (
    <div className="sign-in">
      <h3>Вход в аккаунт</h3>
      {entryErr.password && (
        <div className="err-enter">{entryErr.password}</div>
      )}

      <div>
        <input
          placeholder="Email"
          name="email"
          type="email"
          onChange={changeInputEntry}
        />
        <div className="visible-block-enter">
          <input
            placeholder="Пароль"
            name="password"
            type={isHide ? "password" : "text"}
            onChange={changeInputEntry}
          />
          <img
            onClick={() => setHide(!isHide)}
            className="visible-status"
            src={!isHide ? visible : notvisible}
            alt="visible"
          />
        </div>
      </div>
      <button
        className="btn"
        disabled={Object.keys(entryErr).length !== 0 ? true : false}
        onClick={setSignIn}
      >
        Войти
      </button>
    </div>
  );
};

export default SignIn;
