import axios from "axios";
import { useEffect, useState } from "react";
import "./cart.css";
import { BASE_URL } from "../Enter/Auth/auth";
import { send_request } from "../Enter/Auth/setAuthToken";
import Accordion from "../../components/accordion/accordion";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const OrderPay = () => {
  let [orderPayPal, setOrderPayPal] = useState([]);

  const initialOptions = {
    //"client-id": "AXC3ABQ_pRf5Mu_40enAJEY4v_eRzyq3MiX7LqLhIz-EAmvL0_1wiISi-1lPHf0Li5ADXHHGLOyZnN_s",
    "client-id": "AWpXf6_xVv_dsEtKpko9QV89UKtnVkWEW91nGhNmj60n-aL7hIjHl3ht3WsH49S5tF9QpY24NbGF6C7D",
    currency: "EUR",
  };
  let [story, setStory] = useState();
  let idOrder = window.location.pathname.split("/")[2];
  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();

    axios
      .get(BASE_URL + "orders/" + idOrder)
      .then((res) => {
        setStory(res.data.order);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  }, []);
  useEffect(() => {
    let arr = [];
    story &&
      story.stores &&
      story.stores.map((i) => {
        i.books.map((item) => {
          arr.push({
            name: item.title,
            unit_amount: {
              currency_code: "EUR",
              value: +item.price.toFixed(2),
            },
            quantity: item.quantity,
          });
        });
      });
    setOrderPayPal(arr);
  }, [story]);

  return (
    <div className="story-order container">
      <h2>Мои заказы</h2>
      {story ? (
        <>
          <div className="cart">
            <div className="handler-order">
              <h3>Номер заказа: {story.id}</h3>
              <div className="status-order">{story.status}</div>
            </div>
            <p>{story.createdAt}</p>
            <div>
              {story.stores.map((i, index) => (
                <div key={index}>
                  <div className="brand">{i.brand}</div>
                  <table>
                    {i.books.map((item, ind) => (
                      <tr key={ind}>
                        <td className="img-prod">
                          <img src={item.image} alt={item.title} />
                        </td>
                        <td className="description-prod">
                          <h3>{item.title}</h3>
                        </td>
                        <td className="quantity-prod">
                          <div className="book-quantity">{item.quantity}</div>
                        </td>
                        <td className="price-prod">
                          {item.price.toFixed(2)} €
                        </td>
                      </tr>
                    ))}
                  </table>
                  {story.status == "Ожидает оплаты" && (
                    <>
                      <Accordion
                        title={<b>Покупатель</b>}
                        content={
                          <div>
                            <p>
                              <b>Имя:</b> {story.recipient.firstname}
                            </p>
                            <p>
                              <b>Фамилия:</b> {story.recipient.lastname}
                            </p>
                            <p>
                              <b>Email:</b> {story.recipient.email}
                            </p>
                            <p>
                              <b>Адрес:</b> {story.recipient.address}
                            </p>
                            <p>
                              <b>Номер телефона:</b>{" "}
                              {story.recipient.phoneNumber}
                            </p>
                            <p>
                              <b>Комментарий:</b> {story.recipient.comment}
                            </p>
                          </div>
                        }
                      />
                      <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons
                          fundingSource={"paypal"}
                          onShippingChange={() => {
                            return true;
                          }}
                          style={{ layout: "horizontal" }}
                          onClick={(data, actions) => {}}
                          createOrder={(data, actions) => {
                            return actions.order.create({
                              purchase_units: [
                                {
                                  amount: {
                                    currency_code: "EUR",
                                    value: story.totalPrice,
                                    breakdown: {
                                      item_total: {
                                        currency_code: "EUR",
                                        value: story.totalBooksPrice,
                                      },
                                      shipping: {
                                        value: story.totalShippingPrice,
                                        currency_code: "EUR",
                                      },
                                    },
                                  },
                                  items: orderPayPal,
                                },
                              ],
                              payment_source: {
                                paypal: {
                                  experience_context: {
                                    return_url: BASE_URL + "user/cart",
                                  },
                                },
                              },
                            });
                          }}
                          onApprove={async (data, actions) => {
                            const order = await actions.order.capture();
                            if (localStorage.getItem("access")) {
                              send_request
                                .post(
                                  BASE_URL +
                                    "order/" +
                                    story.id +
                                    "/payment",
                                  {
                                    paymentDetails: data.orderID,
                                    paymentTypeID: 1,
                                  }
                                )
                                .then((res) => {
                                  localStorage.removeItem("orderId");
                                  window.location.assign("/");
                                })
                                .catch((err) => {
                                  if (err.response.status === 500) {
                                    window.location.assign("/error");
                                  }
                                });
                            } else {
                              axios
                                .post(
                                  BASE_URL +
                                    "order/" +
                                    story.id +
                                    "/payment",
                                  {
                                    paymentDetails: data.orderID,
                                    paymentTypeID: 1,
                                  }
                                )
                                .then((res) => {
                                  localStorage.removeItem("orderId");
                                  localStorage.removeItem("cart");
                                  window.location.assign("/");
                                })
                                .catch((err) => {
                                  if (err.response.status === 500) {
                                    window.location.assign("/error");
                                  }
                                });
                            }
                          }}
                          onCancel={() => {
                            //window.location.assign("/");
                          }}
                          onError={(err) => {
                            window.location.assign("/error");
                          }}
                        />
                        <PayPalButtons
                          fundingSource={"card"}
                          onShippingChange={() => {
                            return true;
                          }}
                          style={{ layout: "horizontal" }}
                          onClick={(data, actions) => {}}
                          createOrder={(data, actions) => {
                            return actions.order.create({
                              purchase_units: [
                                {
                                  amount: {
                                    currency_code: "EUR",
                                    value: story.totalPrice,
                                    breakdown: {
                                      item_total: {
                                        currency_code: "EUR",
                                        value: story.totalBooksPrice,
                                      },
                                      shipping: {
                                        value: story.totalShippingPrice,
                                        currency_code: "EUR",
                                      },
                                    },
                                  },
                                  items: orderPayPal,
                                },
                              ],
                              payment_source: {
                                paypal: {
                                  experience_context: {
                                    return_url: BASE_URL + "user/cart",
                                  },
                                },
                              },
                            });
                          }}
                          onApprove={async (data, actions) => {
                            const order = await actions.order.capture();
                            if (localStorage.getItem("access")) {
                              send_request
                                .post(
                                  BASE_URL +
                                    "order/" +
                                    story.id +
                                    "/payment",
                                  {
                                    paymentDetails: data.orderID,
                                    paymentTypeID: 1,
                                  }
                                )
                                .then((res) => {
                                  localStorage.removeItem("orderId");
                                  window.location.assign("/");
                                })
                                .catch((err) => {
                                  if (err.response.status === 500) {
                                    window.location.assign("/error");
                                  }
                                });
                            } else {
                              axios
                                .post(
                                  BASE_URL +
                                    "order/" +
                                    story.id +
                                    "/payment",
                                  {
                                    paymentDetails: data.orderID,
                                    paymentTypeID: 1,
                                  }
                                )
                                .then((res) => {
                                  localStorage.removeItem("orderId");
                                  localStorage.removeItem("cart");
                                  window.location.assign("/");
                                })
                                .catch((err) => {
                                  if (err.response.status === 500) {
                                    window.location.assign("/error");
                                  }
                                });
                            }
                          }}
                          onCancel={() => {
                            //localStorage.removeItem("orderId");
                            //localStorage.removeItem("cart");
                            //window.location.assign("/");
                          }}
                          onError={(err) => {
                            console.log("err", err);
                            window.location.assign("/error");
                          }}
                        />
                      </PayPalScriptProvider>
                    </>
                  )}

                  {story.status == "Оплачено" && (
                    <div>
                      <button
                        onClick={() => {
                          send_request
                            .get(BASE_URL + "user/download-check/" + story.id, {
                              headers: { "Content-Type": "application/pdf" },
                              responseType: "blob",
                            })
                            .then((res) => {
                              const url = window.URL.createObjectURL(
                                new Blob([res.data])
                              );
                              const link = document.createElement("a");
                              link.href = url;
                              link.setAttribute("download", "fileуу.pdf");
                              document.body.appendChild(link);
                              link.click();
                            })
                            .catch((err) => {
                              if (err.response.status === 500) {
                                window.location.assign("/error");
                              }
                            });
                        }}
                        className="btn"
                      >
                        Скачать чек
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>Заказов пока нет</>
      )}
    </div>
  );
};

export default OrderPay;
