import { useEffect, useState } from "react";
import "./enter.css";
import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";
import { Link } from "react-router-dom";

const Enter = () => {
  const [isEnter, setEnter] = useState(true);
  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, []);
  return (
    <div className="enter container">
      {isEnter ? (
        <>
          <SignIn />
          <div>
            <span>Ещё нет аккаунта?</span>
            <div className="alredy-block">
              <a
                className="link-enter"
                onClick={() => {
                  setEnter(false);
                }}
              >
                Зарегистрироваться
              </a>
              <Link to={"reset-password"} className="link-reset">
                Забыли пароль
              </Link>
              <Link to={"/"} className="link-reset">
                Продолжить как гость{" "}
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          <SignUp />
          <div className="alredy-block">
            <span>Уже есть аккаунт? </span>
            <a
              className="link-enter"
              onClick={() => {
                setEnter(true);
              }}
            >
              {" "}
              Войти
            </a>
            <p className="warn-enter">
              Создавая аккаунт, я подтверждаю свое согласие на обработку
              персональных данных в соответствии с{" "}
              <Link to="/confidentiality">
                Уведомлением о конфиденциальности и защите персональных данных
              </Link>{" "}
              и <Link to="/terms">Пользовательским соглашением</Link>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Enter;
