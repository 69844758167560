import axios from "axios";
import { BASE_URL } from "../../pages/Enter/Auth/auth";
import { send_request } from "../../pages/Enter/Auth/setAuthToken";

export const getInfoCart = () => {
  return (dispatch) => {
    if (localStorage.getItem("access")) {
      send_request
        .get(BASE_URL + "user/header")
        .then((res) => {
          dispatch({ type: "DO_THIS", payload: res });
        })
        .catch((err) => {
          if (err.response.status === 500) {
            window.location.assign("/error");
          }
        });
    } else {
      axios
        .post(BASE_URL + "header", {
          books: JSON.parse(localStorage.getItem("cart")),
        })
        .then((res) => {
          dispatch({ type: "DO_THIS", payload: res });
        })
        .catch((err) => {
          if (err.response.status === 500) {
            window.location.assign("/error");
          }
        });
    }
  };
};

export const textSearch = () => {
  return (dispatch) => {
    if (
      window.location.pathname === "/search" ||
      window.location.pathname === "/"
    ) {
      dispatch({
        type: "TEXT_SEARCH",
        payload: localStorage.getItem("s"),
      });
    }
  };
};
