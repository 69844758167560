import axios from "axios";
import { useEffect, useState } from "react";
import "./edit-book.css";
import { BASE_URL } from "../../Enter/Auth/auth";
import { send_request } from "../../Enter/Auth/setAuthToken";
import Accordion from "../../../components/accordion/accordion";
import close from "../../../assets/закрыть.svg";

const EditBook = () => {
  let [countries, setCountries] = useState();
  let [conditions, setConditions] = useState();
  let [categories, setCategories] = useState();
  let [bindings, setBindings] = useState();
  let [img, setImg] = useState();
  let [allImg, setAllImg] = useState();
  let Id = {};
  Id.bookId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  Id.storeId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 3
    ];
  let [data, setData] = useState({
    title: "",
    author: "",
    condition: null,
    quantity: null,
    description: null,
    isbn: null,
    publisher: null,
    format: null,
    releaseYear: null,
    pages: null,
    series: null,
    binding: null,
    price: null,
    weight: null,
    isDamaged: null,
  });
  let [errorData, setErrorData] = useState({
    title: "",
    author: "",
    condition: "",
    quantity: "",
    description: "",
    isbn: "",
    publisher: "",
    releaseYear: "",
    pages: "",
    series: "",
    binding: "",
    price: "",
    weight: "",
    isDamaged: "",
    img: "",
  });

  const getBookData = () => {
    send_request
      .get(BASE_URL + "user/stores/" + Id.storeId + "/books/" + Id.bookId)
      .then((res) => {
        let dataChange = res.data.storeBook;
        dataChange.condition = res.data.storeBook.conditionID;
        dataChange.binding = res.data.storeBook.bindingID;
        if (dataChange.description) {
          dataChange.description = dataChange.description
            .split("\\n")
            .join("\n");
        }

        dataChange.quantity = dataChange.quantity.toString();

        if (dataChange.releaseYear) {
          dataChange.releaseYear = dataChange.releaseYear.toString();
        }

        if (dataChange.pages) {
          dataChange.pages = dataChange.pages.toString();
        }

        dataChange.price = dataChange.price.toFixed(2).toString();
        dataChange.weight = dataChange.weight.toString();

        setData(dataChange);
        setChecked(res.data.storeBook.categoriesIDs);
        setAllImg(res.data.storeBook.images);
      })
      .catch((err) => {
        if (err.response.status == 400) {
          window.location.assign("/not-found");
        }
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
  };
  useEffect(() => {
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
    getBookData();
    axios
      .get(BASE_URL + "countries")
      .then((res) => {
        setCountries(res.data.countries);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 500) {
          window.location.assign("/error");
        }
      });
    axios
      .get(BASE_URL + "bindings")
      .then((res) => setBindings(res.data.bindings));
    axios
      .get(BASE_URL + "conditions")
      .then((res) => setConditions(res.data.conditions));
    axios
      .get(BASE_URL + "categories")
      .then((res) => setCategories(res.data.categories));
  }, []);
  useEffect(() => {
    data && setErrorData(validate(data));
  }, [data]);

  const validate = (values) => {
    let errors = {};

    if (!values.title) {
      errors.title = "Обязательное поле";
    } else if (values.title.length > 80) {
      errors.title = "Поле должно содержать не более 80 символов";
    }

    if (values.description && values.description.length > 4000) {
      errors.description = "Поле должно содержать не более 4000 символов";
    }

    if (values.publisher && values.publisher.length > 65) {
      errors.publisher = "Поле должно содержать не более 65 символов";
    }

    if (!values.author) {
      errors.author = "Обязательное поле";
    } else if (values.author.length > 65) {
      errors.author = "Поле должно содержать не более 65 символов";
    }

    if (!values.bindingID || values.bindingID < 1) {
      errors.bindingID = "Выберите переплет";
    }

    if (!values.conditionID || values.conditionID < 1) {
      errors.conditionID = "Выберите состояние";
    }

    if (isNaN(+values.pages)) {
      errors.pages = "Поле должно содержать число";
    } else if (values.pages && !/^\d+$/.test(values.pages)) {
      errors.pages = "Поле должно содержать целое число";
    } else if (values.pages && +values.pages.toString().length > 6) {
      errors.pages = "Поле должно содержать не более 6 цифр";
    } else if (values.pages && values.pages.length > 1) {
      if (+values.pages[0] === 0) {
        errors.pages = "Поле не должно начинаться с 0";
      }
    }

    if (values.format && values.format.length > 65) {
      errors.format = "Поле должно содержать не более 65 символов";
    }

    if (!values.price || +values.price < 0.01) {
      errors.price = "Обязательное поле";
    } else if (isNaN(+values.price)) {
      errors.price = "Поле должно содержать число";
    } else if (values.price && +values.price > 999999.99) {
      errors.price = "Цена товара не может превышать 999 999.99€";
    } else if (
      values.price &&
      values.price.includes(".") &&
      values.price.toString().split(".")[1].length != 2
    ) {
      errors.price = "Число должно содержать две цифры после точки";
    } else if (values.price && !/^(0.|[1-9]\d*\.)\d{2}$/.test(values.price)) {
      errors.price = "Поле должно содержать число";
    }

    if (!values.quantity || +values.quantity < 1) {
      errors.quantity = "Обязательное поле";
    } else if (values.quantity && +values.quantity > 100) {
      errors.quantity = "Можно добавить не более 100 единиц товара";
    } else if (values.quantity && !/^\d+$/.test(values.quantity)) {
      errors.quantity = "Поле должно содержать целое число";
    } else if (values.quantity && values.quantity.length > 1) {
      if (+values.quantity[0] === 0) {
        errors.quantity = "Поле не должно начинаться с 0";
      }
    }

    if (!values.weight || +values.weight < 1) {
      errors.weight = "Обязательно поле";
    } else if (values.weight && isNaN(+values.weight)) {
      errors.weight = "Поле должно содержать число";
    } else if (values.weight && !/^\d+$/.test(values.weight)) {
      errors.weight = "Поле должно содержать целое число";
    } else if (values.weight && +values.weight.toString().length > 6) {
      errors.weight = "Поле должно содержать не более 6 цифр";
    } else if (values.weight && values.weight.length > 1) {
      if (+values.weight[0] === 0) {
        errors.weight = "Поле не должно начинаться с 0";
      }
    }

    if (values.isbn && values.isbn.length > 65) {
      errors.isbn = "Поле должно содержать не более 65 символов";
    }

    if (isNaN(+values.releaseYear)) {
      errors.releaseYear = "Поле должно содержать число";
    } else if (values.releaseYear && !/^\d+$/.test(values.releaseYear)) {
      errors.releaseYear = "Поле должно содержать целое число";
    } else if (
      values.releaseYear &&
      +values.releaseYear.toString().length > 4
    ) {
      errors.releaseYear = "Поле должно содержать не более 4 цифр";
    } else if (values.releaseYear && values.releaseYear.length > 1) {
      if (+values.releaseYear[0] === 0) {
        errors.releaseYear = "Поле не должно начинаться с 0";
      }
    }

    if (values.series && values.series.length > 65) {
      errors.series = "Поле должно содержать не более 65 символов";
    }

    return errors;
  };

  const setDataStore = () => {
    if (data.description)
      data.description = data.description.split(/\r\n|\r|\n/g).join("\\n");

    let form = new FormData();
    if (data.isbn !== null) {
      data.isbn = data.isbn.toString();
    }

    if (data.isDamaged === "true") {
      data.isDamaged = true;
    } else if (data.isDamaged === "false") {
      data.isDamaged = false;
    }

    data.price = +data.price;
    data.weight = +data.weight;
    data.releaseYear = +data.releaseYear;
    data.pages = +data.pages;
    data.conditionID = +data.conditionID;
    data.quantity = +data.quantity;
    data.bindingID = +data.bindingID;

    let arrImg = [];
    allImg.map((i) => arrImg.push(i.id));
    data.imagesIDs = arrImg;
    data.categoriesIDs = checked;

    form.append("book", JSON.stringify(data));
    send_request
      .patch(
        BASE_URL +
          "user/stores/" +
          Id.storeId +
          "/books/" +
          Id.bookId +
          "/details",
        data
      )
      .then((res) => {
        window.location.assign("/store-books/" + Id.storeId);
      })
      .catch((err) => {
        if (err.response.status === 500 || err.response.status === 400) {
          window.location.assign("/error");
        }
      });
  };

  const changeDataBook = (event) => {
    event.persist();
    setData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const [checked, setChecked] = useState([]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (+event.target.checked) {
      updatedList = [...checked, +event.target.value];
    } else {
      updatedList.splice(checked.indexOf(+event.target.value), 1);
    }
    setChecked(updatedList);
  };
  return (
    <div className="container">
      <h2 className="edit-book-title">Данные книги</h2>
      <div className="user-store cart">
        <div>
          <h3>Картинки</h3>
          <div>
            <input type="file" onChange={(e) => setImg(e.target.files)} />
            <button
              className="btn"
              onClick={() => {
                let formImg = new FormData();
                if (img) {
                  let arr = Array.from(img);
                  arr.map((item) => formImg.append("image", item));
                }
                send_request
                  .post(
                    BASE_URL +
                      "user/stores/" +
                      Id.storeId +
                      "/books/" +
                      Id.bookId +
                      "/images",
                    formImg,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    document.querySelector("input[type=file]").value = "";
                    getBookData();
                  })
                  .catch((err) => {
                    console.log(err);
                    if (err.response.status === 500) {
                      window.location.assign("/error");
                    }
                  });
              }}
              disabled={allImg && allImg.length === 24}
            >
              Добавить
            </button>
          </div>
          <div className="option-book-edit">
            {allImg &&
              allImg.map((i, ind) => (
                <div className="all-img-edit" key={ind}>
                  <img className="img-edit" src={i.link} alt="img" />
                  {allImg.length > 1 && (
                    <div
                      onClick={() => {
                        send_request
                          .delete(
                            BASE_URL +
                              "user/stores/" +
                              Id.storeId +
                              "/books/" +
                              Id.bookId +
                              "/images/" +
                              i.id
                          )
                          .then((res) => {
                            getBookData();
                          })
                          .catch((err) => {
                            console.log(err);
                            if (err.response.status === 500) {
                              window.location.assign("/error");
                            }
                          });
                      }}
                    >
                      <img src={close} className="close-img" alt="close" />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className="block-inp-new-book">
          <label>Название</label>
          <div className="input-new-book">
            <input
              name="title"
              placeholder="Название"
              type="text"
              defaultValue={data && data.title}
              onChange={changeDataBook}
            />
            {errorData.title && <div className="err">{errorData.title}</div>}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Автор</label>
          <div className="input-new-book">
            <input
              name="author"
              placeholder="Автор"
              type="text"
              defaultValue={data && data.author}
              onChange={changeDataBook}
            />
            {errorData.author && <div className="err">{errorData.author}</div>}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Описание</label>
          <div className="input-new-book">
            <textarea
              name="description"
              placeholder="Описание"
              type="text"
              defaultValue={data && data.description}
              onChange={changeDataBook}
            />
            {errorData.description && (
              <div className="err">{errorData.description}</div>
            )}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Состояние</label>
          <div className="input-new-book">
            {conditions && (
              <select
                name="conditionID"
                className="select-country"
                value={data && data.conditionID}
                defaultValue={data && data.conditionID}
                onChange={changeDataBook}
              >
                <option value={0}>Состояние</option>
                {conditions.map((i, ind) => (
                  <option key={ind} value={+i.id}>
                    {i.condition}
                  </option>
                ))}
              </select>
            )}
            {errorData.conditionID && (
              <div className="err">{errorData.conditionID}</div>
            )}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Количество</label>
          <div className="input-new-book">
            <input
              name="quantity"
              placeholder="Количество"
              type="text"
              defaultValue={data && data.quantity}
              onChange={changeDataBook}
            />
            {errorData.quantity && (
              <div className="err">{errorData.quantity}</div>
            )}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Цена</label>
          <div className="input-new-book">
            <input
              name="price"
              placeholder="Цена"
              type="text"
              defaultValue={data && data.price}
              onChange={changeDataBook}
            />
            {errorData.price && <div className="err">{errorData.price}</div>}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Вес в граммах</label>
          <div className="input-new-book">
            <input
              name="weight"
              placeholder="Вес в граммах"
              type="text"
              defaultValue={data && data.weight}
              onChange={changeDataBook}
            />
            {errorData.weight && <div className="err">{errorData.weight}</div>}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>ISBN</label>
          <div className="input-new-book">
            <input
              name="isbn"
              placeholder="ISBN"
              type="text"
              defaultValue={data && data.isbn}
              onChange={changeDataBook}
            />
            {errorData.isbn && <div className="err">{errorData.isbn}</div>}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Издательство</label>
          <div className="input-new-book">
            <input
              name="publisher"
              placeholder="Издательство"
              type="text"
              defaultValue={data && data.publisher}
              onChange={changeDataBook}
            />
            {errorData.publisher && (
              <div className="err">{errorData.publisher}</div>
            )}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Формат</label>
          <div className="input-new-book">
            <input
              name="format"
              placeholder="Формат"
              type="text"
              defaultValue={data && data.format}
              onChange={changeDataBook}
            />
            {errorData.format && <div className="err">{errorData.format}</div>}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Год издания</label>
          <div className="input-new-book">
            <input
              name="releaseYear"
              placeholder="Год издания"
              type="text"
              defaultValue={data && data.releaseYear}
              onChange={changeDataBook}
            />
            {errorData.releaseYear && (
              <div className="err">{errorData.releaseYear}</div>
            )}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Количество страниц</label>
          <div className="input-new-book">
            <input
              name="pages"
              placeholder="Количество страниц"
              type="text"
              defaultValue={data && data.pages}
              onChange={changeDataBook}
            />
            {errorData.pages && <div className="err">{errorData.pages}</div>}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Серия</label>
          <div className="input-new-book">
            <input
              name="series"
              placeholder="Серия"
              type="text"
              defaultValue={data && data.series}
              onChange={changeDataBook}
            />
            {errorData.series && <div className="err">{errorData.series}</div>}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Переплет</label>
          <div className="input-new-book">
            {bindings && (
              <select
                name="bindingID"
                placeholder="Переплет"
                className="select-country"
                value={data && data.bindingID}
                defaultValue={data && data.bindingID}
                onChange={changeDataBook}
              >
                <option value={0}>Переплет</option>
                {bindings.map((i, ind) => (
                  <option key={ind} value={+i.id}>
                    {i.binding}
                  </option>
                ))}
              </select>
            )}
            {errorData.bindingID && (
              <div className="err">{errorData.bindingID}</div>
            )}
          </div>
        </div>

        <div className="block-inp-new-book">
          <label>Повреждения</label>
          <div className="input-new-book">
            <select
              name="isDamaged"
              placeholder="Повреждения"
              className="select-country"
              value={data && data.isDamaged}
              defaultValue={data && data.isDamaged ? data.isDamaged : true}
              onChange={changeDataBook}
            >
              <option value={false}>Нет</option>
              <option value={true}>Есть</option>
            </select>
            {errorData.isDamaged && (
              <div className="err">{errorData.isDamaged}</div>
            )}
          </div>
        </div>
        <h3>Категории</h3>
        {checked.length < 1 && (
          <div className="err">Выберите как минимум одну категорию</div>
        )}
        {checked.length > 30 && (
          <div className="err">Можно выбрать не более 30 категорий</div>
        )}
        <div className="cat-menu">
          {categories &&
            categories.map((i, index) => (
              <div className={"accordion__title"} key={index}>
                {i.subcategories ? (
                  <Accordion
                    active={checked.includes(+i.id) && true}
                    title={
                      <div className={"category-item"}>
                        <input
                          onChange={handleCheck}
                          value={+i.id}
                          checked={checked.includes(+i.id) && true}
                          type="checkbox"
                        />
                        {i.category}
                      </div>
                    }
                    content={i.subcategories.map((item, index) =>
                      item.subcategories ? (
                        <Accordion
                          active={checked.includes(+item.id) && true}
                          title={
                            <div className={"category-item"} key={index}>
                              <input
                                onChange={handleCheck}
                                value={+item.id}
                                checked={checked.includes(+item.id) && true}
                                type="checkbox"
                              />
                              {item.category}
                            </div>
                          }
                          content={item.subcategories.map((subcat, num) => (
                            <div
                              className={"category-item subcat-menu"}
                              key={num}
                            >
                              <input
                                onChange={handleCheck}
                                value={+subcat.id}
                                checked={checked.includes(+subcat.id) && true}
                                type="checkbox"
                              />
                              {subcat.category}
                            </div>
                          ))}
                        />
                      ) : (
                        <div className={"category-item"}>
                          <input
                            onChange={handleCheck}
                            value={+item.id}
                            checked={checked.includes(+item.id) && true}
                            type="checkbox"
                          />
                          {item.category}
                        </div>
                      )
                    )}
                  />
                ) : (
                  <div className={"category-item"}>
                    <input
                      onChange={handleCheck}
                      value={+i.id}
                      checked={checked.includes(+i.id) && true}
                      type="checkbox"
                    />
                    {i.category}
                  </div>
                )}
              </div>
            ))}
        </div>
        <div>
          <button
            onClick={setDataStore}
            disabled={
              Object.keys(errorData).length !== 0
                ? true
                : !allImg
                ? true
                : allImg.length < 1 || allImg.length > 24
                ? true
                : checked.length < 1 || checked.length > 30
                ? true
                : false
            }
            className="btn"
          >
            Сохранить изменения
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditBook;
